import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Menu, Modal } from "antd";
import "./HeaderBottomMenu.scss";
import { getRequest } from "../../../api/http";
import employersServiceApi from "../../../api/employersServiceApi";
import introJs from "intro.js";
import 'intro.js/introjs.css';

function HeaderBottomMenu() {
  const [current, setCurrent] = useState("");
  const allMenus = useParams();
  const allMenuList = Object.values(allMenus)?.toString()?.split("/");
  const homeMenuList = [
    "home",
    "view",
    "applicant",
    "shortlisted",
    "rejected",
    "offered",
    "onHold",
    "jobs",
  ];
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    setCurrent(window.location.pathname);
  };

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const response = await getRequest(employersServiceApi.getAllEvents());
      setEvents(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (homeMenuList.includes(allMenuList[0])) {
      setCurrent("/home");
    } else if (["job-seekers"].includes(allMenuList[0])) {
      setCurrent("/job-seekers");
    } else if (["register-event"].includes(allMenuList[0])) {
      setCurrent("/register-event");
    } else {
      setCurrent("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMenuList]);

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div className="headerBottomRow">
      <Menu
        onClick={handleClick}
        defaultSelectedKeys={["/home"]}
        selectedKeys={[current]}
        defaultOpenKeys={["sub1"]}
        mode="horizontal"
      >
        <Menu.Item key="/home">
          <Link to="/home">Home</Link>
        </Menu.Item>
        <Menu.Item key="/job-seekers" id="jobseekerstep" data-step ="2">
          <Link to="/job-seekers">Job Seekers</Link>
        </Menu.Item>
        {events?.length > 0 && (
          <Menu.Item key="/register-event">
            <Link to="/register-event">Events</Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
}

export default HeaderBottomMenu;
