import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { useParams } from "react-router-dom";
import toastr from 'toastr';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getRequest } from "../../api/http";
import { JobsServiceApi } from "../../api";
import { LoadingView } from "../common";

const ViewAssessment = ({ viewAssessmentModal, setViewAssessmentModal, isAssessmentRequired, studentId }) => {
    const { jobId } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [viewAssessment, setViewAssessment] = useState({})
    const [studentAssessment, setStudentAssessment] = useState({ score: null, result: "" })

    const getStudentAssessment = async () => {
        try {
            const response = await getRequest(JobsServiceApi.getStudentAssessment(jobId, studentId));
            if (response) {
                setViewAssessment(response?.studentJobAssessmentQuestionsOutputs)
                setStudentAssessment({ score: response?.assessmentScore, result: response?.result })
                setIsLoading(false)
            }
        } catch (e) {
            toastr.error(e.message);
            setViewAssessment({})
            setStudentAssessment({})
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isAssessmentRequired) {
            getStudentAssessment()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAssessmentRequired])

    return (
        <div>
            <Modal
                title="View Assessment"
                visible={viewAssessmentModal}
                onOk={() => setViewAssessmentModal(!viewAssessmentModal)}
                onCancel={() => setViewAssessmentModal(!viewAssessmentModal)}
                width={800}
                footer={
                    <Button onClick={() => setViewAssessmentModal(!viewAssessmentModal)} type="secondary">Close</Button>
                }
            >
                <div className="view-assessment">
                    {isLoading ? <LoadingView /> :
                        <React.Fragment>
                            <div className="view-assessment-result">
                                <div className="assessment-result">
                                    <div className="assessment-grid">
                                        <div className="assessment-title">
                                            Assessment Score
                                        </div>
                                        <div className="result-color">
                                            {studentAssessment?.score}
                                        </div>
                                    </div>
                                </div>
                                <div className="assessment-result">
                                    <div className="assessment-grid">
                                        <div className="assessment-title">
                                            Assessment Result
                                        </div>
                                        <div className="result-color">
                                            {studentAssessment?.result}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {viewAssessment?.map((item, i) => {
                                return <div key={i}>
                                    <div className="assessment-question-answer">
                                        <div className="question-with-icon">
                                            <div className="">
                                                {(item?.correctAnswer === item?.selectedAnswer) ?
                                                    <CheckCircleOutlined className="question-correct-answer" /> :
                                                    <CloseCircleOutlined className="question-wrong-answer" />}
                                            </div>
                                            <div className="assessment-question"
                                                dangerouslySetInnerHTML={{ __html: item?.question }}
                                            />
                                        </div>
                                        <div className={`question-options ${item?.correctAnswer === 'OPTION_A' ? 'correct-answer' : item?.selectedAnswer === 'OPTION_A' ? 'wrong-answer': 'options-bg-color'}`}>{item?.optionA}</div>
                                        <div className={`question-options ${item?.correctAnswer === 'OPTION_B' ? 'correct-answer' : item?.selectedAnswer === 'OPTION_B' ? 'wrong-answer':'options-bg-color'}`}>{item?.optionB}</div>
                                        <div className={`question-options ${item?.correctAnswer === 'OPTION_C' ? 'correct-answer' : item?.selectedAnswer === 'OPTION_C' ? 'wrong-answer':'options-bg-color'}`}>{item?.optionC}</div>
                                        <div className={`question-options ${item?.correctAnswer === 'OPTION_D' ? 'correct-answer' : item?.selectedAnswer === 'OPTION_D' ? 'wrong-answer':'options-bg-color'}`}>{item?.optionD}</div>
                                    </div>

                                </div>
                            })}
                        </React.Fragment>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default ViewAssessment