import React, { useEffect } from "react";
import { JobsServiceApi } from "../../api";
import { postRequest } from "../../api/http";
import toastr from "toastr";

export default function ChatScreen() {
    useEffect(() => {
        var LoginUser = JSON.parse(localStorage.getItem('user'));
        const roleName = LoginUser.role.substring(0, 3);
        console.log('LoginUser.freshChatRestoreId', LoginUser.freshChatRestoreId)
        var restoreId = `${LoginUser.freshChatRestoreId}`;
        window.fcWidget.init({
            token: "12cd6efe-0ff9-4c32-8724-0c0d7a73377a",
            host: "https://wchat.in.freshchat.com",
            externalId: `${LoginUser.id}`,
            restoreId: restoreId ? restoreId : null
        });

        window.fcWidget.user.get(function (resp) {
            var status = resp && resp.status;
                // data = resp && resp.data;
            if (status !== 200) {
                window.fcWidget.user.setProperties({
                    firstName: `[${roleName}] - ${LoginUser.name}#${LoginUser.id}`,
                    email: `${LoginUser.email}`,
                    phone: `${LoginUser.phone}`,
                    phoneCountryCode: "+1",
                });
                window.fcWidget.on('user:created', async function (resp) {
                    var status = resp && resp.status,
                        data = resp && resp.data;
                    if (status === 200) {
                        if (data.restoreId) {
                            try {
                                const response = await postRequest(JobsServiceApi.updateRestoreId(), {restoreId:data.restoreId});
                                console.log('data.restoreId', response)
                            } catch (e) {
                                toastr.error(e?.message);
                            }
                        }
                    }
                });
            }
        });
    }, []);
    // useEffect(() => {
    //     window.fcWidget.close();
    // }, []);
    return (
        <div id="chat-container">

        </div>
    );
}