/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Input, Form, Image } from 'antd';
import toastr from 'toastr';
import { UserServiceApi } from '../../../api';
import { postRequest } from '../../../api/http';
import { LoadingView } from '../../common'
import Lady from "../../../assets/images/lady.png";
import { useSearchParams, useNavigate } from "react-router-dom";
import HeaderWithOutAuth from "../../header/headerWithOutAuth"
import './index.scss';


const ResetPassword = (props) => {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const tokenParam = searchParams.get("token")
  const onFinish = async (values) => {
    setloading(true);
    try {
      const response = await postRequest(UserServiceApi.updateResetPassword(tokenParam), values);
      setloading(false);
      toastr.success('Password Created Successfully');
      navigate("/login");
    } catch (e) {
      setloading(false);
      toastr.error(e.message);
    } finally {
      setloading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toastr.error('Please enter new password!');
  };

  function Imagelady() {
    return (
      <Image
        src={Lady}
        preview={false}
      />
    );
  }

  if (loading) return <LoadingView message="Loading..." />;
  return (

    <div className='UpdateResetPassword'>
      <HeaderWithOutAuth />
      <div className="forgetPassword">

        <div className='forgetPasswordRow'>
          <div className='forgetPasswordCol imageLady'>
            <Imagelady />
          </div>
          <div className='forgetPasswordCol'>
            <h2>Change<br />Password</h2>
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >

              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter new password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              {/* <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={['newPassword']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please enter confirm password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item> */}

              <br />
              <Form.Item className="resetBtn">
                <Button type="primary" htmlType="submit">
                  Change Password
                </Button>
              </Form.Item>
            </Form>

          </div>
        </div>
      </div>
    </div >
  );
}

export default ResetPassword;
