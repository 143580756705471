import React from 'react';
import { Image } from 'antd';
import ErrorIcon from '../../assets/images/icons/Pagenotfound.svg';
import { ReactComponent as Logo } from "../../assets/images/timbukdo-logo.svg";
import './internetError.scss';

export default function InternetError(props) {
    return (
        <div className='internetError'>
            <div className="headerTopRow">
                <div className="headerTopCol">
                    <div className="headerTopLogo"><Logo width={120} /></div>
                </div>
            </div>

            <div className="container">
                <div className='errorMsg'>
                    <Image src={ErrorIcon} preview={false} />
                    <h1>Please check your internet connection!</h1>
                </div>
            </div>
        </div>
    );
}
