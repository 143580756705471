/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import toastr from 'toastr';
import {
  Form,
  Input,
  Select,
  Button,
  Image,
  InputNumber,
  Checkbox,
  Modal,
} from 'antd';
import { UserServiceApi } from "../../api";
import { postRequest } from "../../api/http";
import HeaderWithOutAuth from "../header/headerWithOutAuth"
import RegisterImg from "../../assets/images/register.png";
import { LoadingView } from '../common'
import Document from "../../assets/doc/Privacy-Notice.docx";
import TermsAndPrivacyModal from '../common/termsAndPrivacyModal';
import './index.scss';

const Register = (props) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [registerType, setRegisterType] = useState('');
  const [termsandconditions, setTermsandconditions] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [gstinOrPanNumber, setGstinOrPanNumber] = useState('')
  const navigate = useNavigate();
  const { Option } = Select;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const showModal = (value) => {
    setModalValue(value);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(()=>{
    if(gstinOrPanNumber){
      form.setFields([{ name:"gstinOrPanNumber", value:"", errors:"" }])
    }
  },[form, gstinOrPanNumber])

  const onFinish = async (values) => {
    const submitData = {
     ...values, phone: "+91" + values.phone
    }
    setloading(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await postRequest(UserServiceApi.addEmployer(), submitData);
      if (response?.email) {
        setIsEmailVerified(true)
        toastr.success(`Email will be send to the registered email id`);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      toastr.error(error.message);
    }
  };

  const onChangeRegister = (value) => {
    setRegisterType(value);
  };

  const handleGstinOrPanNumber = (value) => {
    setGstinOrPanNumber(value);
  };


  function Imagelady() {
    return (
      <Image
        src={RegisterImg}
        preview={false}
      />
    );
  }

  //More Secure Solution. For JS only.

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  if (loading) return <LoadingView message="Loading..." />;
  return (
    <React.Fragment>
      <HeaderWithOutAuth />
      <div className="register">
        <div className='registrationForm'>
          <div className='RegistrationFormRow'>
            <div className='RegistrationFormCol RegistrationFormBg'>
              <Imagelady />
            </div>
            <div className='RegistrationFormCol RegistrationFormContainer'>
              <h2>Hirer Registration</h2>
              {!isEmailVerified ? <>
                <Form
                  layout="vertical"
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Form.Item
                    name="registeredAs"
                    label="Register as"
                    rules={[{ required: true, message: 'Please select employer register type' }]}
                  >
                    <Select placeholder="Please select employer register type" onChange={onChangeRegister} >
                      <Option value="COMPANY" className='custom-option'>Company</Option>
                      <Option value="INDIVIDUAL"className='custom-option'>Individual</Option>
                    </Select>
                  </Form.Item>
                  {
                    registerType !== "INDIVIDUAL" ? (
                      <React.Fragment>
                        <Form.Item
                          name="name"
                          label=" Company Name"
                          rules={[{ required: true, message: 'Please enter company name' }]}
                        >
                          <Input placeholder="Please enter company name" />
                        </Form.Item>
                        <Form.Item
                          name="gstinOrPanEnum"
                          label="Select GSTIN or PAN"
                          rules={[{ required: true, message: 'Please select employer register type' }]}
                        >
                          <Select onChange={handleGstinOrPanNumber} placeholder="Please select employer register type">
                            <Option value="GSTIN" className='custom-option'>GSTIN No</Option>
                            <Option value="PAN" className='custom-option'>PAN No</Option>
                          </Select>
                        </Form.Item>
                        {gstinOrPanNumber === 'PAN' ? 
                        <Form.Item
                          label='PAN No'
                          name='gstinOrPanNumber'
                          className="formLabel"
                          rules={[
                            { required: true, message: 'PAN No cannot be Empty'},
                            () => ({
                              validator(_, value) {
                                let panValidation = /[A-Z]{5}[0-9]{4}[A-Z]{1}/
                                if (!panValidation.test(value)) {
                                  return Promise.reject('Please Enter Valid PAN No');
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                          validateFirst={true}
                        >
                          <Input placeholder={'Please enter PAN No'} className="formInput" maxLength={10}/>
                        </Form.Item>
                        : gstinOrPanNumber === 'GSTIN' ?
                         <Form.Item
                         label='GSTIN No'
                         name='gstinOrPanNumber'
                         className="formLabel"
                         rules={[
                           { required: true, message: 'GSTIN No cannot be Empty'},
                           () => ({
                             validator(_, value) {
                               let GSTINValidation = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/
                               if (!GSTINValidation.test(value)) {
                                 return Promise.reject('Please Enter Valid GSTIN No');
                               }
                               return Promise.resolve();
                             },
                           }),
                         ]}
                         validateFirst={true}
                       >
                         <Input placeholder={'Please enter GSTIN No'} className="formInput" maxLength={15}/>
                       </Form.Item> : null}
                      </React.Fragment>
                    ) : null
                  }

                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter email',
                      },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                      },
                      // () => ({
                      //   validator(_, value) {
                      //     if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                      //       return Promise.reject(`The input is not valid email`);
                      //     }
                      //     return Promise.resolve();
                      //   },
                      // }),
                    ]}
                    validateFirst={true}
                  >
                    <Input placeholder="Please enter email" />
                  </Form.Item>
                  {
                    registerType === "INDIVIDUAL" ? 
                    <Form.Item
                          name="gender"
                          label="Select gender"
                          rules={[{ required: true, message: 'Please select gender' }]}
                        >
                          <Select placeholder="Please select gender">
                            <Option value="MALE">Male</Option>
                            <Option value="FEMALE">Female</Option>
                          </Select>
                        </Form.Item>
                    :null}
                  <Form.Item
                    name="phone"
                    label="Phone Number"
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone number!",
                      },
                      {
                        validator(_, value) {
                          if (value.toString().length === 10)
                            return Promise.resolve();
                          return Promise.reject(new Error("Invalid phone number"));
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      addonBefore={<span>+91</span>}
                      controls={false}
                      maxLength={10}
                      min={0}
                      // onChange={(val) => setPhone(val)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter password!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder="Please enter password" />
                  </Form.Item>

                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      })
                    ]}
                  >
                    <Input.Password placeholder="Please enter email" />
                  </Form.Item>

                  <Form.Item
                    name="employerUsername"
                    label="User name"
                    rules={[{ required: true, message: 'Please enter user name' }]}
                  >
                    <Input placeholder="Please enter user name" />
                  </Form.Item>
                  <Form.Item
                    name="address"
                    label="Address"
                    rules={[{ required: true, message: 'Please enter address' }]}
                  >
                    <Input.TextArea
                      placeholder="Please enter address"
                      showCount
                      maxLength={1000}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Pin code"
                    name="pincode"
                    rules={[{ required: true, message: 'Please enter pin code' }]}
                  >
                    <InputNumber
                      placeholder="Please enter pin code"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  {
                    // registerType !== "INDIVIDUAL" ? (
                    //   <React.Fragment>
                    //     <Form.Item
                    //       name="pan"
                    //       label="PAN"
                    //       rules={[{ required: true, message: 'Please enter PAN number' }]}
                    //     >
                    //       <Input placeholder="Please enter PAN number" />
                    //     </Form.Item>
                    //     <Form.Item
                    //       name="aadhaar"
                    //       label="Aadhaar"
                    //       rules={[{ required: true, message: 'Please enter Aadhaar number' }]}
                    //     >
                    //       <Input placeholder="Please enter Aadhaar number" />
                    //     </Form.Item>
                    //   </React.Fragment>
                    // ) : null
                  }

                  <Form.Item
                    name="termsandconditions"
                    label="Terms and Conditions"
                    rules={[{ required: false, message: 'Please agree terms and conditions' }]}
                  >
                    <Checkbox onChange={() => setTermsandconditions(!termsandconditions)} checked={termsandconditions ? true : false}></Checkbox> I agree to the <span className='policyLink' onClick={() => showModal('PrivacyPolicy')}>Privacy Policy</span> & <span className='policyLink' onClick={() => showModal('TermsOfService')}>Terms of Service</span> by signing in.
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={!termsandconditions ? true : false}>
                      Register
                    </Button>
                  </Form.Item>
                </Form>

                <div className="registerBox">
                  <span>Already have an account ?<Link className="registerLink" to="/login"> Login</Link></span>
                </div>
              </> :

                <div className='isEmailVerified'>
                  <p>Thank you for registering with us. The registration details were sent to your mail-id.</p>
                  <Button onClick={() => navigate("/login")} type="primary" className="forgetPasswordBtn">
                    Login
                  </Button>
                </div>
              }

            </div>
          </div>
        </div>
      </div>
      <TermsAndPrivacyModal
        modalValue={modalValue}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </React.Fragment>
  );
};

export default Register;
