import { useState, useEffect } from "react";
import { Layout } from "antd";
import {
  Routes,
  Route,
  useNavigate,
  // useLocation,
} from "react-router-dom";
import toastr from "toastr";
import { UserServiceApi } from "../api";
import { getRequest } from "../api/http";
import { LoadingView, RequireAuth } from "./common";
import TopMenu from "./header/headerWithAuth/TopMenu";
import HeaderBottomMenu from "./header/headerWithAuth/HeaderBottomMenu";
// import HeaderAdminMenu from "./header/headerWithAuth/HeaderAdminMenu";
import Chat from "./chat";

/// EMPLOYER SCREENS ///
import Home from "./home";
import JobSeekers from "./jobSeekers";
import JobsView from "./jobsDetails";
import JobsDetails from "./createJob";
import Applicant from "./jobStatus/applicant";
import Rejected from "./jobStatus/rejected";
import Offered from "./jobStatus/offered";
import OnHold from "./jobStatus/onHold";
import ShortListed from "./jobStatus/shortListed";
import JobListView from "./jobStatus/view";
import OfferAccepted from "./jobStatus/offer_accepted";
import OfferRejected from "./jobStatus/offer_rejected";
import ResumeView from "./resumeView";
import ResetPassword from "./resetPassword";
import Profile from "./profile";
import EditProfile from "./profile/editProfile";
import EmployerEvent from "./EmployerEvent/EmployerEvent";
/// ADMIN SCREENS ///
import PageNotFound from "./admin/pageNotFound";

//JOBSEEKER_ADMIN SCREENS///

// import Merchant from "./admin/merchant";
import KYC from "./kyc";
import Subscription from "./subscription";
import SubscriptionHistory from "./subscription/subscriptionHistory";
import InternetError from "./common/internetError";
import KycStatus from "./kyc/KycStatus";

//Placement officer screens



const { Header, Content } = Layout;

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-center",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

function AppMain() {
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();
  // const location = useLocation();
  const isAuthenticated = localStorage.getItem("token");
  const [isInternetStatus, setIsInternetStatus] = useState(true);

  useEffect(() => {
    function changeStatus() {
      setIsInternetStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      const response = await getRequest(UserServiceApi.getUserDetails());
      setUserRole(response?.role);
      localStorage.setItem("user", JSON.stringify(response));
      localStorage.setItem("role", response?.role);
      setUser(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);
  // console.log(userRole);

  const refreshFunction = () => {
    fetchUserDetails();
  };
  // useEffect(() => {
  //   const unauthorizedError = () => {
  //     setUser({});
  //     localStorage.removeItem("userBgColor");
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("role");
  //     localStorage.clear();
  //     window.fcWidget.destroy();
  //     navigate("/login", { state: { from: location } });
  //   };
  //   document.addEventListener("unauthorizedError", unauthorizedError);
  //   return () => {
  //     document.removeEventListener("unauthorizedError", unauthorizedError);
  //   };
  // }, [location, navigate]);

  if (!isAuthenticated) {
    navigate("/login");
  }

  if (loading) return <LoadingView />;

  if (!isInternetStatus) return <InternetError />;

  return (
    <div>
      <Header className="header">
        <div className="headerContainer">
          <TopMenu
            collapsed={collapsed}
            user={user}
            handleCollapse={handleCollapse}
          />
          {/* {userRole && userRole === "ADMIN" ? (
            <HeaderAdminMenu />
          ) : userRole === "EMPLOYER" ? (
            <HeaderBottomMenu />
          ) : userRole === "SUB_ADMIN" ? (
            <HeaderSubAdmin />
          ) : userRole === "EMPLOYER_ADMIN" ? (
            <HeaderEmployerAdminMenu />
          ) : userRole === "JOBSEEKER_ADMIN" ? (
            <HeaderJobseekerAdminMenu />
          ) : null} */}
          {
            userRole && userRole === "EMPLOYER" ? (
              <HeaderBottomMenu />
            ) : null
          }
        </div>
      </Header>
      {userRole === "EMPLOYER" ? <Chat /> : null}
      <Layout
        className="layoutStyle"
      >
        <Content>
          <Routes>
            <Route path="/subscription" element={<Subscription />} />
            <Route
              path="/subscriptionHistory"
              element={<SubscriptionHistory />}
            />
            {userRole && userRole === "EMPLOYER" ? (
              <>
                <Route
                  path="/"
                  element={
                    <RequireAuth user={user}>
                      <Home user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/home"
                  element={
                    <RequireAuth user={user}>
                      <Home user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/job-seekers"
                  element={
                    <RequireAuth user={user}>
                      <JobSeekers user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/jobs-details/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <JobsView user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/jobs/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <JobsDetails user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/jobs/duplicate"
                  element={
                    <RequireAuth user={user}>
                      <JobsDetails user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/jobs/create-job"
                  element={
                    <RequireAuth user={user}>
                      <JobsDetails user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/applicant/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <Applicant user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/rejected/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <Rejected user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/offered/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <Offered user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/onHold/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <OnHold user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/shortlisted/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <ShortListed user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/view/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <JobListView user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/offer-accepted/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <OfferAccepted user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/offer-rejected/:jobId"
                  element={
                    <RequireAuth user={user}>
                      <OfferRejected user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/view-profile/:stdName"
                  element={
                    <RequireAuth user={user}>
                      <ResumeView user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/ResetPassword"
                  element={
                    <RequireAuth user={user}>
                      <ResetPassword user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <RequireAuth user={user}>
                      <Profile user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/kyc-status"
                  element={
                    <RequireAuth user={user}>
                      <KycStatus user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/profile/:id"
                  element={
                    <RequireAuth user={user}>
                      <EditProfile user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/KYC"
                  element={
                    <RequireAuth user={user}>
                      <KYC user={user} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/register-event"
                  element={
                    <RequireAuth user={user}>
                      <EmployerEvent user={user} />
                    </RequireAuth>
                  }
                />
                <Route path="*" element={<PageNotFound />} />
              </>
            ) : (
              navigate("/login")
            )}
          </Routes>
        </Content>
      </Layout>
    </div>
  );
}

export default AppMain;
