import { API_URL } from './http';

const login = () => `${API_URL}/login`;
const deviceToken=()=>`${API_URL}/employers/current/device-token`
const deleteToken=()=>`${API_URL}/employers/current/logout`
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    login,
    deviceToken,
    deleteToken
}
