import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useParams } from 'react-router';
import toastr from "toastr";
import { JobsServiceApi } from "../../api";
import { patchRequest } from "../../api/http";

const DeleteQuestion = ({ deleteQuestionModal, setDeleteQuestionModal, questionData, setQuestionData, questionDeleteData, fetchQuestionByJob }) => {

    const { jobId } = useParams();

    const handleDelete = async () => {
        if (jobId) {
            if (questionDeleteData?.id) {
                 // delete 1 question by question id 
                try {
                    const response = await patchRequest(JobsServiceApi.deleteQuestion(jobId, questionDeleteData?.id));
                if (response) {
                    toastr.success("Question Deleted Successfully");
                    fetchQuestionByJob(jobId)
                    setDeleteQuestionModal(!deleteQuestionModal)
                } 
                } catch (error) {
                    toastr.error(error || error?.message);
                    setDeleteQuestionModal(!deleteQuestionModal)
                }
               
            } else {
                // delete all the questions
                try {
                    const response = await patchRequest(JobsServiceApi.deleteAllQuestion(jobId));
                    if (response) {
                        toastr.success("Question Deleted Successfully");
                        fetchQuestionByJob(jobId)
                        setDeleteQuestionModal(!deleteQuestionModal)
                    }
                } catch (error) {
                    toastr.error(error || error?.message);
                    setDeleteQuestionModal(!deleteQuestionModal)
                }
               
            }
        } else {
            if (questionDeleteData?.id) {
                let res = questionData?.filter(del => del?.id !== questionDeleteData?.id)
                if (res) {
                    setQuestionData(res)
                    toastr.success("Question Deleted Successfully");
                    setDeleteQuestionModal(!deleteQuestionModal)
                }
            } else {
                setQuestionData([])
                toastr.success("Questions Deleted Successfully");
                setDeleteQuestionModal(!deleteQuestionModal)
            }
        }
    }

    return (
        <Modal
            title={false}
            visible={deleteQuestionModal}
            onOk={() => setDeleteQuestionModal(!deleteQuestionModal)}
            onCancel={() => setDeleteQuestionModal(!deleteQuestionModal)}
            footer={null}>
            <div className="admin-kyc-success-modal">
                <div className="kyc-success-modal-text">
                    Are you sure want to Delete this Question{questionDeleteData?.id ? "" : "s"}?
                </div>
                <div className="kyc-success-btn">
                    <Button
                        type="primary submit"
                        htmlType="submit"
                        className="kyc-success-ok-btn"
                        onClick={() => handleDelete()}
                    >
                        OK
                    </Button>
                    <Button type="secondary submit"
                        htmlType="submit"
                        onClick={() => setDeleteQuestionModal(!deleteQuestionModal)}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteQuestion