import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import EmployerHome from './employerHome';
import "./index.scss";

export default function Home(props) {
  const location = useLocation();
  const userRole=localStorage.getItem('role')
  if (!userRole) {
    return <Navigate to="/login" state={{ from: location }} />;
  } 

  return (
    <div className="container">
      {userRole &&  userRole === "EMPLOYER" ? <EmployerHome /> : <></>}
    </div>
  );
}
