import React, { useState } from "react";
// import { render } from "react-dom";
import './index.scss';

const Star = ({ selected = false, onClick = f => f }) => (
  <div className={selected ? "star selected" : "star"} onClick={onClick} />
);

export default function StarRating({ starData, totalStars, starRate }) {
  const [starsSelected, selectStar] = useState(starRate);
  const starSelect = (i) => {
    let addStar = (i + 1)
    selectStar(addStar)
    starData(addStar)
  }

  return (
    <div className="star-rating">
      {[...Array(totalStars)].map((n, i) => (
        <div key={i} className="starRating">
          <Star
            key={i}
            selected={i < starsSelected}
            onClick={() => starSelect(i)}
          />
          <span className="ratingNo">{i + 1}</span>
        </div>
      ))}
      {
        // <p>
        //   {starsSelected} of {totalStars} stars
        // </p>
      }
    </div>
  );
}
