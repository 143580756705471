import React, { useEffect, useState } from 'react';
import { Input, Button, Table, Select, Row, Col } from 'antd';
import { useNavigate, Link } from "react-router-dom";
import toastr from 'toastr';
import { ControlOutlined } from '@ant-design/icons';
import { JobsServiceApi } from '../../api';
import { getRequest, postRequest } from '../../api/http';
import { LoadingView } from '../common'
import JobFilter from './jobFilter';
import './index.scss'

const jobSeekersFilter = [
  { label: 'Skills', value: "skills" },
  { label: 'Rating', value: "rating" }
]

export default function UsersPage(props) {
  const windowScreen = window.innerWidth;
  const { Search } = Input;
  const { Option } = Select;
  const navigate = useNavigate();
  const [loading, setloading] = useState(true)
  const [jobsList, setJobsList] = useState([]);
  const [latestJob, setLatestJob] = useState('');
  const [candidateList, setCandidateList] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNumber] = useState(0)
  const [pageSize] = useState(30)
  const [filterValue, setFilterValue] = useState([])


  const fetchAllCandidates = async (jobId) => {
    const searchName = searchValue !== '' ? `&name=${searchValue}` : ''
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await getRequest(JobsServiceApi.getAllCandidates(jobId, pageNumber, pageSize, searchName, filterValue));
      setCandidateList(response.result);
      setloading(false)
    } catch (e) {
      setloading(false)
      setCandidateList([]);
      toastr.error(e.message);
    }
  };

  const fetchJobsListByStatus = async (jobStatus) => {
    try {
      const response = await getRequest(JobsServiceApi.getJobByStatus(jobStatus));
      if (response.content?.length >= 1) {
        setJobsList(response.content)
        setLatestJob(response.content[0].id)
        fetchAllCandidates(response.content[0].id);
        setloading(false)
      } else {
        setJobsList([])
        setloading(false)
      }
    } catch (err) {
      toastr.error(err.message);
      setJobsList([])
      setLatestJob([])
      setloading(false)
      // fetchAllCandidates([]);
    }
  }
  useEffect(() => {
    fetchJobsListByStatus('PUBLISH');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (latestJob) {
      fetchAllCandidates(latestJob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const changeCandidateSts = async (userId) => {
    try {
      await postRequest(JobsServiceApi.candidateNotify(latestJob, userId));
      toastr.success('Notified Successfully');
      fetchAllCandidates(latestJob)
    } catch (e) {
      toastr.error(e.message);
    }
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Skills',
      dataIndex: 'skills',
      key: 'skills',
      render: (text, record) => (
        <div className='textOverFlow'>
          {text.map(item => item).join(', ')}
        </div>
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (text, record) => (
        <div>{text !== null ? text : '--'}</div>
      )
      // render: (text, record) => (
      //   <div className='jobseekerStarRating'>
      //     {[...Array(5)].map((n, i) => (
      //       <div key={i} className="starRating">
      //         <Star
      //           key={i}
      //           selected={i < text}
      //         />
      //         <span className="ratingNo">{i + 1}</span>
      //       </div>
      //     ))}
      //   </div>
      // )
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (record, rowIndex) => {
        return (
          <React.Fragment>
            <span className='jobSeekerActionBtn'>
              <Link
                to={`/view-profile/${rowIndex.userId}`}
                state={{ profileUserId: `${rowIndex.userId}`,jobId:latestJob }}
                className="TableVewResumeBtn"
              >View Profile</Link>
            </span>
            {record === null ? (<Button onClick={() => { changeCandidateSts(rowIndex?.userId) }} type='primary' className='btnNotify'>Notify</Button>) : null}
            {record === "NOTIFIED" ? (<Button type='primary' className='btnNotified' disabled>Notified</Button>) : null}
            {record === "APPLIED" ? (<Button type='primary' className='btnApplied' disabled>Applied</Button>) : null}
            {record === "NOT_INTERESTED" ? (<Button type='primary' className='btnNotInterested' disabled>Not interested</Button>) : null}
          </React.Fragment>
        )
      },
    },
  ];

  const columnMobile = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (record, rowIndex) => {
        return (
          <React.Fragment>
            <span className='jobSeekerActionBtn'>
              <Link
                to={`/view-profile/${rowIndex.userId}`}
                state={{ profileUserId: `${rowIndex.userId}` }}
                className="TableVewResumeBtn"
              >View Profile</Link>
            </span>
            {record === null ? (<Button onClick={() => { changeCandidateSts(rowIndex?.userId) }} type='primary' className='btnNotify'>Notify</Button>) : null}
            {record === "NOTIFIED" ? (<Button type='primary' className='btnNotified' disabled>Notified</Button>) : null}
            {record === "APPLIED" ? (<Button type='primary' className='btnApplied' disabled>Applied</Button>) : null}
            {record === "NOT_INTERESTED" ? (<Button type='primary' className='btnNotInterested' disabled>Not interested</Button>) : null}
          </React.Fragment>
        )
      },
    },
  ];

  const onSearch = async (value) => {
    const searchName = value !== '' ? `&name=${value}` : ''
    setSearchValue(value)
    setloading(true)
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await getRequest(JobsServiceApi.getAllCandidates(latestJob, pageNumber, pageSize, searchName, ''));
      setCandidateList(response?.result);
    } catch (e) {
      // toastr.error(e.error.message);
    } finally {
      setloading(false)
    }
  }

  const handleChange = (value) => {
   
    setLatestJob(value)
    fetchAllCandidates(value);
  };

  const handleFilter = () => {
    setIsModalVisible(!isModalVisible);
  }

  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
    setFilterValue([])
  };

  const renderCandidatesList = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <div>
        {
          windowScreen > 667 ? (
            <Table dataSource={candidateList} columns={columns} className="candidatesListTable desktopTableView" />
          ) : (
            <Table
              className='mobileTableView candidatesListTable'
              columns={columnMobile}
              dataSource={candidateList}
              pagination={false}
              rowKey={(record) => record?.userId}
              expandable={{
                expandedRowRender: (record) => (
                  <div className='expandableContent'>
                    <div className='expandableRow'>
                      <div className='expandableColHead'>Skills</div>
                      <div className='expandableColValue textOverFlow'>
                        {
                          (record.skills || []).map((sk, i) => (
                            <span key={i}>{sk}, </span>
                          ))
                        }
                      </div>
                    </div>
                    <div className='expandableRow'>
                      <div className='expandableColHead'>Rating</div>
                      <div className='expandableColValue'>

                        <div className='jobseekerStarRating'>
                        <div>{record?.rating !== null ? record?.rating : '--'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              }}
            />
          )
        }
      </div>
    )
  };

  return (
    <div className="container">
      <div className="headingContainer">
        <div className="headingTitle"><h3>All Candidates</h3></div>
        <div className="headingButton">
          <Button className="btn ant-btn ant-btn-primary" onClick={() => navigate(-1)}>Back</Button>
        </div>
      </div>
      <div className="applicant">
        <Row className='searchContainer jobSeekerRow'>
          <Col xs={{ span: 19 }} sm={{ span: 21 }} lg={{ span: 22 }}>
            <h4>Select posted Job</h4>
            <Select
              value={`${latestJob}`}
              onChange={handleChange}
            >
              {jobsList.map((item, i) => (
                <Option key={i} value={item.id}>{item.title}</Option>
              ))}
            </Select>
          </Col>
          <Col xs={{ span: 5 }} sm={{ span: 2 }} lg={{ span: 2 }} className="filterSearchContainer">
            <Button onClick={handleFilter} className='jobSeekerSearchButton' type="primary" icon={<ControlOutlined />} >
            </Button>
          </Col>
        </Row>
        <JobFilter
          visible={isModalVisible}
          onOk={handleOk}
          jobCancel={handleCancel}
          options={jobSeekersFilter}
          setFilterValue={setFilterValue}
        />
        <div className='searchRow'>
          <Search placeholder="Find a Candidate" onSearch={onSearch} enterButton />
        </div>
        {renderCandidatesList()}
      </div>
    </div>
  );
}