import { API_URL } from "./http";

const getEmployerDetails = (employerId) => `${API_URL}/admins/current/employers/${employerId}`;
//all deals api
const getAllDeals = () => `${API_URL}/admins/current/deals`;
const getAllProducts = () => `${API_URL}/admins/current/shopify/products`;
const getEmployersbyCampaign=()=>`${API_URL}/admins/current/employers/active/by/whatsapp-campaign`
const getAllEvents=()=>`${API_URL}/employers/current/events`;
const getAllApprovedEvents=()=>`${API_URL}/employers/current/events/approved`;
const registerEvent=()=>`${API_URL}/employers/current/event/register`;
const getEvent=(id)=>`${API_URL}/employers/current/event/${id}`;  
const CalculateTotal=()=>`${API_URL}/employers/current/calculate-custom-subscription-amount`
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllDeals,
    getAllProducts,
    getEmployerDetails,
    getAllEvents,
    registerEvent,
    getAllApprovedEvents,
    getEvent,
    getEmployersbyCampaign,
    CalculateTotal,
}
