import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button, Image, Row, Col, Modal } from 'antd';
import toastr from 'toastr';
import moment from 'moment';
import { JobsServiceApi } from '../../api';
import { getRequest, postRequest } from '../../api/http';
import { LoadingView } from '../common';
import PdfImg from '../../assets/images/pdf.png';
import './index.scss';
import jobsServiceApi from '../../api/jobsServiceApi'; 
import whatsAppIcon from '../../assets/images/wp-icon.svg'
import html2pdf from 'html2pdf.js';
export default function ResumeView(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const userId = useParams();
  console.log(location,'location')
  let {profileUser,jobId} = location?.state
  const [loading, setloading] = useState(true)
  const [studentInfo, setStudentInfo] = useState([]);
  const [subscribeError, setSubscribeError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false)
  const userRole = localStorage.getItem('role')
  console.log("userId", userId);
  console.log("studentInfo", studentInfo);
  const fetchStudentsDetails = async (studentId) => {
    try {
      // eslint-disable-next-line no-unused-vars
      let pathUrl = userRole === 'ADMIN' ? getRequest(JobsServiceApi.getResumeViewAdmin(studentId)) : getRequest(JobsServiceApi.getResumeDetails(studentId,jobId))
      const response = await pathUrl;
      setStudentInfo(response);
      setloading(false)
    } catch (e) {
      toastr.error(e.message);
      setStudentInfo([]);
      if (e.message === 'Please Subscribe to View New Resume' || e.message === "Please subscribe to view resume") {
        setSubscribeError(true);
        setloading(false)
      }
    } finally {
      setloading(false)
    }
  };
  const handleWhatsAppClick = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters from phone number
    const whatsappLink = `https://wa.me/${formattedPhoneNumber}?text=Hello%20there!`;
    try {
        window.open(whatsappLink, '_blank');
    } catch (error) {
        console.error('Error opening WhatsApp link:', error);
        toastr.error('Error opening WhatsApp');
    }
};
const handleDownloadPDF = () => {
  const element = document.querySelector('.resumeView');

  html2pdf().from(element).set({
    margin: 10,
    filename: `${studentInfo.name}_resume.pdf`,
    image: { type: 'jpeg', quality: 1.0 },
    html2canvas: {
      scale: 4, // Increase the scale to improve quality
      logging: true, // Enable logging for debugging
      useCORS: true // Enable CORS to handle cross-origin images
    },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  }).save();
};
  useEffect(() => {
    if (userId) {
      fetchStudentsDetails(userId?.stdName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleArchive = async (status) => {
    const submitData = {
      "users": [
        userId?.stdName
      ],
      "archive": status
    }

    try {
      const response = await postRequest(jobsServiceApi.adminArchiveStudent(), submitData);
      if (response) {
        toastr.success("Student Archived successfully");
        navigate('/viewchart/job_seekers/All')
        // fetchStudentsDetails(userId?.stdName);
      }
    } catch (error) {
      toastr.error("Somthing went wrong!");
    }
  }
  if (loading) return <LoadingView message="Loading..." />;
  if (subscribeError) return <div className='subscribeError'>You don't have Resume Views, Please Subscribe</div>;

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const ViewUploadedDocuments = () => {
    return <Modal
      bodyStyle={{ height: 400 }}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      className="modalInfo"
      okText="Continue"
      cancelText="Back"
      centered
      footer={<div className=" flex justify-center sm:mb-4 xs:mb-4 gap-2">
        <button
          onClick={() => handleCancel()}
          className="w-24 h-8 text-base text-pink-500 hover:text-pink-500 bg-white hover:bg-white border border-pink-500 hover:border-pink-500 rounded-md">
          Cancel
        </button>
      </div>}
    >
      <img width={460} height={380} src={profileUser?.idCardUrl} alt={profileUser?.name} />
    </Modal>
  }
  return (
    <div className="container">
      <div className="headingContainer">
        <div className="headingTitle"></div>
        <div className="headingButton">
          {userRole && userRole === 'ADMIN' ?
            <React.Fragment>
              <Button onClick={() => { handleArchive(studentInfo?.archive ? false : true) }} className="btn ant-btn ant-btn-primary admin-block" >{studentInfo?.archive ? "Un Block" : "Block"}</Button>
              <Button disabled={profileUser?.idCardUrl ? false : true} onClick={() => setIsModalVisible(true)} className="btn ant-btn ant-btn-primary admin-block" >Uploaded Documents</Button>
            </React.Fragment>
            : null}
          <Button className="btn ant-btn ant-btn-primary" onClick={handleDownloadPDF} style={{marginRight:"10px"}}> 
            Download Resume
          </Button>
          <Button className="btn ant-btn ant-btn-primary" onClick={() => navigate(-1)}>Back</Button>
        </div>
        {ViewUploadedDocuments()}
      </div>
      <div className="resumeView">
        <div>
          <div className='profileHead'>
            <div className='userImg'>
              <Image src={studentInfo.profileUrl||'https://www.shareicon.net/data/512x512/2016/07/26/802043_man_512x512.png'} preview={false} style={{height:'144px',width:'144px'}}/>
            </div>
            <div className='userInfo'>
              <h2>{studentInfo?.name}</h2>
              <p>{studentInfo?.email}</p>
            </div>
          </div>
          {
            studentInfo?.about !== null ? (
              <div className='aboutMe'>
                <h3>About Me</h3>
                <div className='resumeInfo'>
                  <p>{studentInfo?.about}</p>
                </div>
              </div>
            ) : null
          }


          <div className='contactDetails'>
            <h3>Contact Details</h3>
            <div className='resumeInfo'>
              <ul>
                {studentInfo?.email !== null ? (
                  <li>
                    <span className='head'>Email:</span>
                    <span className='info'>{studentInfo.email}</span>
                  </li>
                ) : null}
                {studentInfo?.phone !== null ? (
                  <li>
                    <span className='head'>Contact Number:</span>
                    <span className='info'>{studentInfo.phone}   <Image src={whatsAppIcon}  preview={false} style={{height:'24px',width:'24px',marginLeft:'15px'}} onClick={()=>handleWhatsAppClick(studentInfo.phone)}/></span>
                    
                  </li>
                ) : null}
                {studentInfo?.address !== null ? (
                  <li>
                    <span className='head'>Address:</span>
                    <span className='info'>{studentInfo?.address}</span>
                  </li>
                ) : null}
                {studentInfo?.dob !== null ? (
                  <li>
                    <span className='head'>Date of Birth:</span>
                    <span className='info'>{new Date(studentInfo.dob).toLocaleDateString('fr-FR')}</span>
                  </li>
                ) : null}
                {studentInfo?.gender !== null ? (
                  <li>
                    <span className='head'>Gender</span>
                    <span className='info camelCase'>{studentInfo?.gender?.replace(/_/g, " ")}</span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>

          {studentInfo?.education && studentInfo?.education[0]?.college &&
            <div className='contactDetails'>
              <h3>Educational Details</h3>
              <div className='resumeInfo'>
                <ul>
                  {studentInfo?.education?.map((edu, i) => {
                    return <React.Fragment key={i}>
                      <li>
                        <span className='head'>College Name:</span>
                        <span className='info'>{edu?.college}</span>
                      </li>
                      <li>
                        <span className='head'>Course :</span>
                        <span className='info'>{edu?.course}</span>
                      </li>
                      <li>
                        <span className='head'>Start Date :</span>
                        <span className='info'>{moment(edu?.start).format('DD-MM-YYYY')}</span>
                      </li>
                      <li>
                        <span className='head'>End Date :</span>
                        <span className='info'>{moment(edu?.end).format('DD-MM-YYYY')}</span>
                      </li>
                    </React.Fragment>
                  })}
                </ul>
              </div>
            </div>}
          <div className='contactDetails'>
            <h3>Work Experience</h3>
            {studentInfo?.workExperience && studentInfo?.workExperience ?
              <div className='resumeInfo '>
                <ul>
                  <Row >
                    {studentInfo?.workExperience?.map((exp, i) => {
                      return <React.Fragment key={i}>
                        <Col className="gutter-row workExperience" lg={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                          <li>
                            <span className='head'>Company Name:</span>
                            <span className='info'>{exp?.companyName}</span>
                          </li>
                          <li>
                            <span className='head'>Designation :</span>
                            <span className='info'>{exp?.role}</span>
                          </li>
                          <li>
                            <span className='head'>Start Date :</span>
                            <span className='info'>{moment(exp?.startDate).format('DD-MM-YYYY')}</span>
                          </li>
                          <li>
                            <span className='head'>End Date :</span>
                            <span className='info'>{moment(exp?.endDate).format('DD-MM-YYYY')}</span>
                          </li>
                        </Col>
                      </React.Fragment>
                    })}
                  </Row>
                </ul>
              </div>
              :
              <div className='resumeInfo'>
                <ul>
                  <li> No experience</li>
                </ul>
              </div>}
          </div>

          {studentInfo?.languages.length !== 0 &&
            <div className='skillsDetails'>
              <h3>Languages</h3>
              <div className='resumeInfo'>
                <ul>
                  <li>{studentInfo?.languages.join(", ")}</li>
                </ul>
              </div>
            </div>}
          {studentInfo?.skills.length !== 0 &&
            <div className='skillsDetails'>
              <h3>Skills</h3>
              <div className='resumeInfo'>
                <ul>
                  <li>{studentInfo?.skills.join(", ")}</li>
                </ul>
              </div>
            </div>}


          {/* <div className='My Preferences'>
            <h3>Job Preferences</h3>
            <div className='resumeInfo'>
              <ul>
                <li>
                  <span className='head'>Preferred Timings:</span>
                  <span className='info'>9 AM to 1 PM</span>
                </li>
                <li>
                  <span className='head'>Preferred Work hours:</span>
                  <span className='info'>Work from home</span>
                </li>
              </ul>
            </div>
          </div> */}
          {
            studentInfo?.portfolio !== null ? (
              <div className='MyWorksAndLinks'>
                <h3>Student Portfolio</h3>
                <div className='resumeInfo studentPortFolio'>
                  <Row className='imgContainer' gutter={[40, 40]}>
                    {studentInfo?.portfolio.map((portfolio, i) => (
                      portfolio?.fileUrl !== null ? portfolio?.fileUrl.substr(portfolio?.fileUrl?.lastIndexOf('.') + 1) === 'png' ||
                        portfolio?.fileUrl.substr(portfolio?.fileUrl?.lastIndexOf('.') + 1) === 'jpg' ||
                        portfolio?.fileUrl.substr(portfolio?.fileUrl?.lastIndexOf('.') + 1) === 'jpeg' ? <Col className='imgContainer' xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                        <div className='imgRow'>
                          <Image src={portfolio.fileUrl} preview={true} />
                        </div>
                      </Col> : null : null
                    ))}
                    {studentInfo?.portfolio.map((portfolio, i) => (
                      portfolio?.fileUrl !== null ? portfolio?.fileUrl.substr(portfolio?.fileUrl?.lastIndexOf('.') + 1) === 'pdf' ?
                        <Col className='imgContainer' xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                          <div className='imgRow'>
                            <a href={portfolio.fileUrl} rel="noopener noreferrer" target="_blank"><Image src={PdfImg} preview={false} /></a>
                          </div>
                        </Col> : null : null
                    ))}

                  </Row>
                  <div className='workLinks'>
                    {studentInfo?.portfolio?.map((portfolio, i) => (
                      <div>
                        <a href={portfolio?.workUrl} rel="noopener noreferrer" target="_blank">{portfolio?.workUrl}</a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null
          }

        </div>
      </div>
    </div>
  );
}
