import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Dropdown, Image } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { ReactComponent as Logo } from "../../../assets/images/timbukdo-logo.svg";
import ProfileLogo from "../../../assets/images/headerProfileLogo.png";
import { postRequest } from "../../../api/http";
import LoginApi from "../../../api/LoginApi";
import introJs from "intro.js";
import 'intro.js/introjs.css';
import "./TopMenu.scss";

const TopMenu = (props) => {
  const [userName, setUserName] = useState("");
  const { user } = props;
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");

  const menu = () => {
    return (
      <Menu onClick={onActionClick} className="profileInfoPopup">
        <Menu.Item key="userName">
          <Link to="/profile">Company Profile</Link>
        </Menu.Item>
        <Menu.Item key="kyc">
          <Link to="/KYC">KYC</Link>
        </Menu.Item>
        <Menu.Item key="subscription">
          <Link to="/subscription">Subscription</Link>
        </Menu.Item>
        <Menu.Item key="resetPassword">
          <Link to="/resetPassword">Password Settings</Link>
        </Menu.Item>
        <Menu.Item key="logout">Logout</Menu.Item>
      </Menu>
    );
  };

  const menuPo = () => {
    return (
      <Menu onClick={onActionClick} className="profileInfoPopup">
        <Menu.Item key="userName">
          <Link to="/profile">Profile</Link>
        </Menu.Item>
        <Menu.Item key="resetPassword">
          <Link to="/resetPassword">Password Settings</Link>
        </Menu.Item>
        <Menu.Item key="logout">Logout</Menu.Item>
      </Menu>
    );
  };

  const onActionClick = async({ key }) => {
    if (key === "logout") {
      const token=localStorage.getItem("deviceToken")
      if(token){
        const payload={
          fcmDeviceToken:token
        }
        const response= await postRequest(LoginApi.deleteToken(),payload)
      }
      console.log('key', key)
      localStorage.removeItem("userBgColor");
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.clear();
      navigate("/login");
      window.fcWidget.destroy();
    }
  };

  useEffect(() => {
    // console.log('user.profileUrl', user.profileUrl);
    if (user.name) setUserName(user.name);
  }, [user.name]);

  function ImageLogo() {
    return <Logo width={120} />;
  }

  return (
    <React.Fragment>
      <div className="headerTopRow">
        <div className="headerTopCol">
          <div className="headerTopLogo">
            <Link to="/">
              <ImageLogo />
            </Link>
          </div>
        </div>
        <div className="headerTopCol">
          <ul>
            <li>
              <h5>
                {userName}
                <small>{user.email}</small>
              </h5>
            </li>
            {userRole === "EMPLOYER" ? (
              <li>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <span
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Image
                      src={user.profileUrl ? user.profileUrl : ProfileLogo}
                      preview={false}
                      width={38}
                      id="dpstep"
                      data-step ="1"
                    />
                  </span>
                </Dropdown>
              </li>
            ) : (
              <li>
                <Menu onClick={onActionClick} className="adminLogout">
                  <Menu.Item key="logout">
                    <LogoutOutlined style={{ color: "#2C4394" }} />
                  </Menu.Item>
                </Menu>
              </li>
            )}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopMenu;
