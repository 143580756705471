import React, { useState, useEffect } from "react";
import './index.scss';

const Star = ({ selected = false, onClick = f => f }) => (
  <div className={selected ? "star selected" : "star"} onClick={onClick} />
);

export default function StarRating({ starData, totalStars, starRate }) {
  const [starsSelected, selectStar] = useState(starRate);
  const starSelect = (i) => {
    let addStar = (i + 1)
    selectStar(addStar)
    starData(addStar)
  }

  useEffect(()=>{
    if(starRate === '' || starRate === 0){
      selectStar(0)
      starData(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [starRate])

  return (
    <div className="star-rating">
      {[...Array(totalStars)].map((n, i) => (
        <div key={i} className="starRating">
          <Star
            key={i}
            selected={i < starsSelected}
            onClick={() => starSelect(i)}
          />
          <span className="ratingNo">{i + 1}</span>
        </div>
      ))}
    </div>
  );
}
