
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import toastr from 'toastr';
import { JobsServiceApi } from '../../api';
import { getRequest, postRequest, putRequest } from '../../api/http';
import { Table, Button, Pagination, Modal, Input, Form } from 'antd';
import StarRating from '../../components/starRating';
import ViewAssessment from './viewAssessment';
import './index.scss';

export default function StudentListTable({ onClick, status, jobId, jobStatus, currentJob ,setStudentList,studentList}) {
  const [form] = Form.useForm();
  const [selectedStudentsList, setSelectedStudentsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [jobOfferId, setJobOfferId] = useState('')
  const [viewAssessmentModal, setViewAssessmentModal] = useState(false)
  const [studentId, setStudentId] = useState('')
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [responseBlob, setResponseBlob] = useState('');
  const [isExpLetterModalOpen, setIsExpLetterModalOpen] = useState(false);
  const [jobIdForExpLetter, setJobIdForExpLetter] = useState('');
  const [stdIdForExpLetter, setStdIdForExpLetter] = useState('');
  const [urlForExpLetter, setUrlForExpLetter] = useState('');

  const fetchStudentsList = async (jobId, jobStatus, pageNumber) => {
    try {
      const response = await getRequest(JobsServiceApi.jobsApplicantsListByStatus(jobId, jobStatus, pageNumber));
      setSelectedStudentsList(response);
      setTotalElements(response.totalElements)
      if (setStudentList) { // Check if setStudentList is passed as a prop
        setStudentList(response.content);  // Update the parent component with the student list
      }
    } catch (e) {
      toastr.error(e.message);
    }
  };

  useEffect(() => {
    if (jobId) {
      fetchStudentsList(jobId, jobStatus, '0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const applicantStatus = async (stdId, status, termsAndCon) => {
    try {
      const response = await putRequest(JobsServiceApi.getJobsByApplicationStatus(jobId, stdId), { "action": status, termsAndConditions: termsAndCon });
      fetchStudentsList(jobId, jobStatus, '0');
      onClick();
      if (response && isModalOpen) {
        setIsModalOpen(!isModalOpen)
      }
    } catch (err) {
      toastr.error(err.message);
    }
  }

  const handleCancelLetter = () =>{
    setIsExpLetterModalOpen(false);
  }

  const handleOkLetter =  async () =>{
    try {
      const res = await postRequest(JobsServiceApi.generateExperienceLetterPost(jobIdForExpLetter, stdIdForExpLetter))
      if (res) {
          toastr.success("Data sent to student successfully");
      }
    } catch (error) {
        toastr.error(error?.message);
    }
      setIsExpLetterModalOpen(false);
  }

  const generateExperienceLetter = async (jobId, stdId, url) => {
    setJobIdForExpLetter(jobId);
    setStdIdForExpLetter(stdId);
    setUrlForExpLetter(url);
    try {
      getRequest(JobsServiceApi.generateExperienceLetter(jobId, stdId, url)).then((response) => {
        console.log("response-->>", response);
        setResponseBlob(response);
      if (response) {
        setIsExpLetterModalOpen(!isExpLetterModalOpen)
      }
      });
    } catch (err) {
      toastr.error(err.message);
    }
  }

  const showExperienceLetter = () => {
    return <Modal
    width="60vw"
    title={""}
        bodyStyle={(urlForExpLetter ? {height: 500} : {height: 600})}
        visible={isExpLetterModalOpen}
        onOk={handleOkLetter}
        onCancel={handleCancelLetter}
        maskClosable={false}
        className="modalInfo"
        okText="Continue"
        cancelText="Back"
        centered
        footer={(urlForExpLetter ? 
            null
          :
          <div>
          <Button onClick={() => handleCancelLetter()} className='secondary' key="Cancel">Cancel</Button>
          <Button onClick={handleOkLetter} key="OK" type="primary">Send</Button>
       </div>
       )}
    >
      <div dangerouslySetInnerHTML={{__html:responseBlob}}>                 
            </div>
    </Modal>
}
 
  function onChangeGetTableData(pageNumber) {
    setCurrentPage(pageNumber)
    fetchStudentsList(jobId, jobStatus, pageNumber - 1);
  }

  const ratefunction = async (starCount, id) => {
    saveRating(starCount, id)
  }

  const saveRating = async (starCount, id) => {
    try {
      await putRequest(JobsServiceApi.addStarRating(jobId, id), { "rating": starCount });
      toastr.success('Rating Added Successfully');
    } catch (err) {
      toastr.error(err?.message);
    }
  }

  const handleViewAssessment = (student) => {
    setStudentId(student?.id)
    setViewAssessmentModal(!viewAssessmentModal)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => text,
    },
    {
      title: 'Skills',
      dataIndex: 'skills',
      key: 'skills',
      render: text => (
        <div className='textOverFlow'>
          {text.join(', ')}
        </div>
      )
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (text, record) => (
        <div className='ratingBox'>
          {jobStatus === 'OFFER_ACCEPTED' ? (
            <React.Fragment>
              <StarRating starData={(e) => ratefunction(e, record.id)} totalStars={5} starRate={record.rating} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {record.rating !== null ? record.rating : '--'}
            </React.Fragment>
          )}
        </div>
      ),
    },
    {
      title: 'Profile Match(%)',
      dataIndex: 'matchingPercentage',
      key: 'profilematch',
      render: text => text,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div className='ActionBox'>
          {jobStatus !== '' ? (
            <React.Fragment>
              <Link
                className='ant-btn statusBtn'
                to={`/view-profile/${record.id}`}
                state={{ profileUserId: record.id ,jobId:jobId,}}
              >View Profile</Link>
              {record.applicationStatus === "APPLIED" ? (
                <React.Fragment>
                  <Button className='statusBtn' onClick={() => applicantStatus(record.id, "SHORTLISTED", null)}>Shortlist</Button>
                </React.Fragment>
              ) : null}
              {record.applicationStatus === "SHORTLISTED" ? (
                <React.Fragment>
                  <Button className='statusBtn' onClick={() => handleModal(record.id)}>Offer</Button>
                  <Button className='statusBtn' onClick={() => applicantStatus(record.id, "IN_REVIEW", null)}>On Hold</Button>
                </React.Fragment>
              ) : null}

              {record.applicationStatus === "IN_REVIEW" ? (
                <React.Fragment>
                  <Button className='statusBtn' onClick={() => handleModal(record.id)}>Offer</Button>
                </React.Fragment>
              ) : null}
              {record.applicationStatus !== "OFFERED" &&
                record.applicationStatus !== "OFFER_ACCEPTED" ? (
                <React.Fragment>
                  {
                    record.applicationStatus !== "OFFER_REJECTED" ? (
                      <React.Fragment>
                        {record.applicationStatus !== "REJECTED" ? (
                          <React.Fragment>
                            <Button className='statusBtn' onClick={() => applicantStatus(record.id, "REJECTED", null)}>Reject</Button>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    ) : null
                  }
                </React.Fragment>
              ) : null
              }
              {record.applicationStatus !== "VIEWED" &&
                <React.Fragment>
                  {currentJob?.isAssessmentRequired ?
                    <Button className='statusBtn' onClick={() => handleViewAssessment(record)}>View Assessment</Button>
                    : null}
                </React.Fragment>}
                {record.applicationStatus === "OFFER_ACCEPTED" ? (
                <React.Fragment>
                  <Button className='statusBtn' onClick={() => generateExperienceLetter(jobId, record.id, record.experienceCertificate)}>Experience Letter</Button>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Link
                className='ant-btn statusBtn'
                to={`/view-profile/${record.id}`}
                state={{ profileUserId: record.id ,jobId:jobId,}}
              > View Profile</Link>
            </React.Fragment>
          )}


        </div>
      ),
    },
  ];

  const columns2 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => text,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div className='ActionBox'>
          {jobStatus !== '' ? (
            <React.Fragment>
              <Link
                className='ant-btn statusBtn'
                to={`/view-profile/${record.id}`}
                state={{ profileUserId: record.id ,jobId:jobId,}}
              >View Profile</Link>
              {record.applicationStatus === "APPLIED" ? (
                <React.Fragment>
                  <Button className='statusBtn' onClick={() => applicantStatus(record.id, "SHORTLISTED", null)}>Shortlist</Button>
                  {/* <Button className='statusBtn' onClick={() => applicantStatus(record.id, "IN_REVIEW")}>On Hold</Button> */}
                </React.Fragment>
              ) : null}
              {record.applicationStatus === "SHORTLISTED" ? (
                <React.Fragment>
                  <Button className='statusBtn' onClick={() => handleModal(record.id)}>Offer</Button>
                  <Button className='statusBtn' onClick={() => applicantStatus(record.id, "IN_REVIEW", null)}>On Hold</Button>
                </React.Fragment>
              ) : null}

              {record.applicationStatus === "IN_REVIEW" ? (
                <React.Fragment>
                  <Button className='statusBtn' onClick={() => handleModal(record.id)}>Offer</Button>
                </React.Fragment>
              ) : null}
              {record.applicationStatus !== "REJECTED" ? (
                <React.Fragment>
                  <Button className='statusBtn' onClick={() => applicantStatus(record.id, "REJECTED", null)}>Reject</Button>
                </React.Fragment>
              ) : null}
              {record.applicationStatus === "OFFER_ACCEPTED" ? (
                <React.Fragment>
                  <Button className='statusBtn' onClick={() => generateExperienceLetter(jobId, record.id, record.experienceCertificate)}>Experience Letter</Button>
                </React.Fragment>
              ) : null}

            </React.Fragment>
          ) : (
            <React.Fragment>
              <Link
                className='ant-btn statusBtn'
                to={`/view-profile/${record.id}`}
                state={{ profileUserId: record.id ,jobId:jobId, }}
              > View Profile</Link>
            </React.Fragment>
          )}


        </div>
      ),
    },
  ];

  const handleModal = (jobId) => {
    setJobOfferId(jobId)
    setIsModalOpen(!isModalOpen)
  }

  const handleCloseModal = () => {
    setJobOfferId('')
    setIsModalOpen(!isModalOpen)
  }

  const onFinish = async (values) => {
    applicantStatus(jobOfferId, "OFFERED", values?.termsAndCondition ? values?.termsAndCondition : null)
  }
  const singleEmployerView = () => {
    return (
      <Modal
        visible={isModalOpen}
        onOk={() => setIsModalOpen(!isModalOpen)}
        onCancel={handleCloseModal}
        // width={800}
        footer={false}
      >
        <div className='terms-and-service'>
          <Form
            layout="vertical"
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name="termsAndCondition"
              label="Additional terms and condition (Optional)"
            // rules={[{ required: true, message: 'Please enter termsAndCondition' }]}
            >
              <Input.TextArea
                placeholder=""
                showCount
                maxLength={1000}
                rows={6}
              />
            </Form.Item>
            <div className='terms-offer-btn'>
              <Button type="primary submit terms-service-btn" htmlType="submit">Skip</Button>
              <Button type="primary submit" htmlType="submit">Proceed</Button>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }

  return (
    <div className="studentListTable">
      {
        screenSize > 667 ? (
          <Table
            className='desktopTableView'
            columns={columns}
            dataSource={selectedStudentsList.content}
            pagination={false}
            rowKey={(record) => record.id}
          />
        ) : (
          <Table
            className='mobileTableView'
            columns={columns2}
            dataSource={selectedStudentsList.content}
            pagination={false}
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: (record) => (
                <div className='expandableContent'>
                  <div className='expandableRow'>
                    <div className='expandableColHead'>Skills</div>
                    <div className='expandableColValue'>
                      <div className='textOverFlow'>
                        {record.skills?.join(', ')}
                      </div>
                    </div>
                  </div>
                  <div className='expandableRow'>
                    <div className='expandableColHead'>Rating</div>
                    <div className='expandableColValue'>
                      <div className='ratingBox'>
                        {jobStatus === 'OFFER_ACCEPTED' ? (
                          <React.Fragment>
                            <StarRating starData={(e) => ratefunction(e, record.id)} totalStars={5} starRate={record.rating} />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {record.rating}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='expandableRow'>
                    <div className='expandableColHead'>Profile Match(%)</div>
                    <div className='expandableColValue'>
                      <div className='ratingBox'>
                        <div>{record.matchingPercentage}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        )
      }

      <div className='paginationRow'>
        
        <Pagination
          current={currentPage}
          pageSize={20}
          total={totalElements}
          onChange={onChangeGetTableData}
        />
      </div>
      {singleEmployerView()}
      {
        currentJob?.isAssessmentRequired && studentId ?
          <ViewAssessment
            viewAssessmentModal={viewAssessmentModal}
            setViewAssessmentModal={setViewAssessmentModal}
            isAssessmentRequired={currentJob?.isAssessmentRequired}
            studentId={studentId}
            jobDetails={currentJob}
          />
          : null
      }
      {showExperienceLetter()}
    </div>
  );
}
