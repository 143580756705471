import React, { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Button } from 'antd';
import StudentListTable from '../../../components/studentListTable'
import JobCard from '../../jobCard';
import './index.scss';


export default function OfferAccepted(props) {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [status, setStatus] = useState(false);
  const [currentJob, setCurrentJob] = useState([])

  const OnclickChangeStatus = () => {
    setStatus(!status)
  }

  const renderJobInfo = () => {
    return (
      <JobCard jobId={jobId} status={status} setCurrentJob={setCurrentJob}/>
    )
  }

  return (
    <div className="container">
      <div className="headingContainer">
        <div className="headingTitle"><h3>Job Offer Accepted</h3></div>
        <div className="headingButton">
          <Button className="btn ant-btn ant-btn-primary" onClick={() => navigate(-1)}>Back</Button>
        </div>
      </div>
      <div className="JobsViewList">
        {renderJobInfo()}
        <StudentListTable currentJob={currentJob} jobId={jobId} jobStatus='OFFER_ACCEPTED' onClick={() => OnclickChangeStatus()} status={status} />
      </div>
    </div>
  );
}
