import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "antd";
import { Link } from "react-router-dom";
import toastr from 'toastr';
import { JobsServiceApi, UserServiceApi } from '../../api';
import { getRequest } from '../../api/http';
import { LoadingView } from '../common'
import ProfileLogo from "../../assets/images/companyProfile.png";
import "./index.scss";

const Profile = () => {
  const [employer, setEmployer] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [profileUrl, setProfileUrl] = useState(null);


  const fetchEmployer = async () => {
    setProfileUrl(null);
    try {
      const response = await getRequest(JobsServiceApi.getEmployerProfile());
      setEmployer(response);
      setIsLoading(false)

      fetchProfileImage();
    } catch (e) {
      setIsLoading(false)
      setEmployer([]);
      toastr.error(e.message);
    }
  };

  const fetchProfileImage = async () => {
    try {
      const response = await getRequest(UserServiceApi.getProfileImage());
      setProfileUrl(response.profileUrl)
      console.log('fetchProfileImage', response.profileUrl);
    } catch (e) {
      toastr.error(e.message);
    }
  };

  useEffect(() => {
    fetchEmployer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (isLoading) return <LoadingView message="Loading..." />;
  return (
    <React.Fragment>
      <div className="headingContainer">
        <div className="headingTitle">
          <h3>Profile</h3>
        </div>
        <div className="headingButton">
          <Link to={`/profile/${employer.id}`} state={{ employerData: employer }}> <Button type="primary">Edit</Button></Link>
        </div>
      </div>

      <div className="adminHome">
        <div className="profile">
          <div className="profileCard">
            <Row gutter={16}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="profileImage">
                  <div className="imageConatainer">
                    <div className="imageRow">
                      <img src={profileUrl !== null ? profileUrl : ProfileLogo} alt="defaultLogo" className="imageSize" />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                <div className="profileSection">
                  {employer?.registeredAs === "COMPANY" ?
                    <div className="ProfileTitle">
                      <h2>{employer.companyName}</h2>
                    </div>
                    : null}
                  <div className="ProfileFeatures">
                    {/* <h3>Active</h3> */}
                    < h3 className={employer?.isKycCompleted ? 'kycActiveStatus' : ''} > KYC - {employer?.isKycCompleted ? 'Verified' : 'Pending'}</h3>
                  </div>
                  <div className='companyProfile'>
                    {employer?.registeredAs === "COMPANY" ?
                      <React.Fragment>
                        <div className='companyProfileHead'>
                          Company Description:
                        </div>
                        <div className='companyProfileDetail textOverFlow'>
                          {employer?.companyDescription !== null || '' ? employer?.companyDescription : "--"}
                        </div>
                        {employer?.gstin &&
                          <React.Fragment>
                            <div className='companyProfileHead'>
                              GSTIN No:
                            </div>
                            <div className='companyProfileDetail'>
                              {employer?.gstin !== null || '' ? employer?.gstin : "--"}
                            </div></React.Fragment>}
                        {employer?.pan &&
                          <React.Fragment>
                            <div className='companyProfileHead'>
                              PAN No:
                            </div>
                            <div className='companyProfileDetail'>
                              {employer?.pan !== null || '' ? employer?.pan : "--"}
                            </div></React.Fragment>}
                      </React.Fragment> : null
                    }
                    <div className='companyProfileHead'>
                      Contact Name:
                    </div>
                    <div className='companyProfileDetail'>
                      {employer?.contactName}
                    </div>
                    <div className='companyProfileHead'>
                      Email:
                    </div>
                    <div className='companyProfileDetail'>
                      {employer?.email}
                    </div>
                    {employer?.registeredAs === "INDIVIDUAL" ?
                      <React.Fragment>
                        <div className='companyProfileHead'>
                          Gender:
                        </div>
                        <div className='companyProfileDetail'>
                          {employer?.employerGender !== null ? employer?.employerGender : "--"}
                        </div>
                      </React.Fragment>
                      : null}
                    <div className='companyProfileHead'>
                      Phone
                    </div>
                    <div className='companyProfileDetail'>
                      {employer?.phone !== null ? employer?.phone : "--"}
                    </div>
                    <div className='companyProfileHead'>
                      Address
                    </div>
                    <div className='companyProfileDetail textOverFlow'>
                      {employer?.address}
                    </div>
                    <div className='companyProfileHead'>
                      City
                    </div>
                    <div className='companyProfileDetail'>
                      {employer?.city}
                    </div>
                    <div className='companyProfileHead'>
                      State
                    </div>
                    <div className='companyProfileDetail'>
                      {employer?.state}
                    </div>
                    <div className='companyProfileHead'>
                      Pincode
                    </div>
                    <div className='companyProfileDetail'>
                      {employer?.pincode}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

        </div>
      </div>
    </React.Fragment >
  );
};

export default Profile;
