import React, { useState, useEffect } from 'react';
import { Input, Button, Row, Col, Form, Select, Checkbox, Image } from 'antd';
import toastr from 'toastr';
import { JobsServiceApi, UserServiceApi } from '../../api';
import { postRequest, getRequest } from '../../api/http';
import Check from "../../assets/images/check.png";
import TermsAndPrivacyModal from '../common/termsAndPrivacyModal';
import { LoadingView } from '../common';
import "./index.scss";
import { useLocation } from 'react-router';

const individualOptions = [
    { label: "Pan Card No", value: "panCard" },
    { label: "Aadhar Card", value: "aadharCard" },
    { label: "Voter Id", value: "voterId" },
    { label: "Driving Licence", value: "drivingLicence" },

    // { label: "Passport No", value: "passport" },
]

const companyOptions = [
    { label: "GSTIN Number", value: "GSTIN" },
    { label: "CIN Number", value: "CIN" },
    { label: "Business Pan", value: "businessPan" }
]

export default function Kyc() {
    const { Option } = Select;
    const [form] = Form.useForm();
    const location =useLocation()
    const kycCompleted=location?.state?.kycCompleted
    // console.log(kycCompleted,'kyc-status')
    const [isLoading, setIsLoading] = useState(false)
    const [verifiedKyc, setVerifiedKyc] = useState({})
    const [checkedStatus, setCheckedStatus] = useState(false);
    const [documentOption, setDocumentOption] = useState([])
    const [documentType, setDocumentType] = useState('')
    const [kycValue, setKycValue] = useState({ label: "Document Number", name: "" })
    const [validation, setValidation] = useState('')
    const [apiEndPoint, setApiEndPoint] = useState(null)
    const [tsTransID, setTsTransID] = useState('')
    const [isOtp, setIsOtp] = useState(false)
    const [isKycVerified, setIsKycVerified] = useState(false)
    const [panSubmitList, setPanSubmitList] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalValue, setModalValue] = useState('');
    const [kycStatusLoading, setKycStatusLoading] = useState(true)
    const [userStatus, setUserStatus] = useState(null)
    const panValidation = /[A-Z]{5}[0-9]{4}[A-Z]{1}/
    // const aadharValidation = /^\d{4}\d{4}\d{4}$/
    const voterIdValidation = /^([a-zA-Z]){3}([0-9]){7}?$/
    // const passportValidation = /[A-Z]{1}[0-9]{7}/
    // const licenseValidation = /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/
    const gstinValidation = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/
    const cinValidation = /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/


    const showModal = (value) => {
        setModalValue(value);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getEmployerKycStatus = async () => {
        try {
            const response = await getRequest(UserServiceApi.getUsersKycStatus());
            setUserStatus(response)
            if (response) {

                const verifiedKyc = Object.keys(response).reduce((p, c) => {
                    if (response[c]) p[c] = response[c];
                    return p;
                }, []);
                // console.log(response, verifiedKyc, 'respomse')
                if (response?.registeredAs === 'COMPANY') {
                    setKycStatusLoading(false)
                    setDocumentOption(companyOptions)
                    if (response?.isCinVerified || response?.isGstinVerified || response?.isPanVerified) {  //
                        setVerifiedKyc(verifiedKyc)
                        setIsKycVerified(true)
                    }
                } else {
                    setKycStatusLoading(false)
                    setDocumentOption(individualOptions)
                    if (response?.isAadhaarVerified || response?.isDrivingLicenseVerified || response?.isPanVerified || response?.isVoterVerified) {
                        setIsKycVerified(true)
                        setVerifiedKyc(verifiedKyc)
                    }
                }
                if(kycCompleted){
                    toastr.error("Kyc verification unsuccessful, please try again")
                }
            }
        } catch (err) {
            toastr.error(err.message);
            setKycStatusLoading(false)
        }
    }

    useEffect(() => {
        getEmployerKycStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (documentType === "panCard") {
            // setKycValue({ label: "Pan No", name: "panNumber" })
            // setValidation(panValidation)
            setApiEndPoint(JobsServiceApi.panVerify())
            // form.setFields([{ name:"docNumber", value:"", errors:"" }])
        } else if (documentType === "aadharCard") {
            // setKycValue({ label: "Aadhar No", name: "aadharCard" })
            // setValidation(aadharValidation)
            setApiEndPoint(JobsServiceApi.aadhaarVerify())
            // form.setFields([{ name:"docNumber", value:"", errors:"" }])
        } else if (documentType === "voterId") {
            setKycValue({ label: "Voter Id", name: "voterId" })
            setValidation(voterIdValidation)
            setApiEndPoint(JobsServiceApi.voterIdVerify())
            form.setFields([{ name: "docNumber", value: "", errors: "" }])
        }
        // else if (documentType === "passport") {
        //     setKycValue({ label: "Passport No", name: "passport" })
        //     setValidation(passportValidation)
        //     setApiEndPoint(JobsServiceApi.passportVerify())
        //     form.setFields([{ name:"docNumber", value:"", errors:"" }])
        // }
        else if (documentType === "drivingLicence") {
            // setKycValue({ label: "Driving Licence", name: "drivingLicence" })
            // setValidation(licenseValidation)
            setApiEndPoint(JobsServiceApi.drivingLicenseVerify())
            // form.setFields([{ name:"docNumber", value:"", errors:"" }])
        } else if (documentType === "GSTIN") {
            setKycValue({ label: "GSTIN No", name: "GSTINNo" })
            setValidation(gstinValidation)
            setApiEndPoint(JobsServiceApi.gstinVerify())
            form.setFields([{ name: "docNumber", value: "", errors: "" }])
        } else if (documentType === "CIN") {
            setKycValue({ label: "CIN No", name: "CINNo" })
            setValidation(cinValidation)
            setApiEndPoint(JobsServiceApi.cinVerify())
            form.setFields([{ name: "docNumber", value: "", errors: "" }])
        }
        else if (documentType === "businessPan") {
            setKycValue({ label: "Business Pan (Please provide the company's PAN information, not a personal PAN)", name: "businessPan" })
            setValidation(panValidation)
            setApiEndPoint(JobsServiceApi.businessPanVerify())
            form.setFields([{ name: "docNumber", value: "", errors: "" }])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentType])

    const saveKfc = async (values) => {
        console.log(values)
        const voterSubmitData = {
            documentType: "VOTER_ID",
            idNumber: values.docNumber,
        }

        const gstSubmitData = {
            documentType: "GSTIN",
            idNumber: values.docNumber,
        }
        const cinSubmitData = {
            documentType: "CIN",
            idNumber: values.docNumber,
        }
        const businessSubmitData = {
            documentType: "PAN",
            idNumber: values.docNumber,
        }

        // const passportSubmitData = {
        //     ...values,
        //     "dateOfBirth": moment(values?.dateOfBirth).format('DD-MM-YYYY'),
        //     "dateOfExpiry": moment(values?.dateOfExpiry).format('DD-MM-YYYY'),
        // }

        const submitDatas = documentType === "voterId" ? voterSubmitData :
            documentType === "GSTIN" ? gstSubmitData : documentType === "CIN" ? cinSubmitData : documentType === "businessPan" ? businessSubmitData : values
        setIsLoading(true)
        try {
            if (userStatus.registeredAs ==='COMPANY' || documentType === "voterId") {
                const response = await postRequest(apiEndPoint, submitDatas);
                // if (response?.tsTransID) {
                //     setPanSubmitList(documentType === "panCard" ? panSubmitData : {})
                //     setIsOtp(true)
                //     setTsTransID(response?.tsTransID)
                //     toastr.success("Your OTP has been send to your mobile number");
                //     setIsLoading(false)
                // } else {
                //     setIsOtp(false)
                //     setTsTransID('')
                if ((!response.isKycCompleted && !(
                    response.isAadhaarVerified || response.isPanVerified || response.isDrivingLicenseVerified ||
                    response.passportVerified || response.isCinVerified || response.isGstinVerified || response.isVoterVerified
                ))) {
                    console.log("came")
                    toastr.error("Kyc verification unsuccessful, please try again.");
                    setIsLoading(false);
                    return;
                }
        
                // If no error, mark KYC as verified
                setIsKycVerified(!isKycVerified);
                toastr.success("KYC created successfully");
                setIsLoading(false);
                getEmployerKycStatus();
            }else{
                const response=await postRequest(apiEndPoint)
                console.log(response,'came')
                window.location.href = response.data.url;
            }
            // }
        } catch (e) {
            toastr.error(e.message);
            setIsLoading(false)
        }
    };
    const verifyOtpNumber = async (values) => {
        const pathName = documentType === "panCard" ? JobsServiceApi.panOtpVerify() : JobsServiceApi.aadhaarOtpVerify()
        const submitData = {
            ...values,
            ...panSubmitList,
            tsTransID
        }
        setIsLoading(true)
        try {
            const response = await postRequest(pathName, submitData);
            if (response) {
                setIsOtp(false)
                setTsTransID('')
                setIsKycVerified(!isKycVerified)
                setIsLoading(false)
                toastr.success("KYC created successfully");
            }
        } catch (e) {
            toastr.error(e.message);
            setIsLoading(false)
        }
    };

    if (isOtp) return <div className='container'>
        <div className="headingContainer">
            <div className="headingTitle"><h3>KYC</h3></div>
            <div className="headingButton">

            </div>
        </div>
        <Form
            layout="vertical"
            onFinish={verifyOtpNumber}
        >
            <div
                className="formItems">
                <div className='kycCard'>
                    <Row className='' gutter={16}>
                        <Col className="gutter-row" xs={{ span: 24 }}>
                            <Form.Item
                                label="OTP Number"
                                name="otp"
                                rules={[{ required: true, message: `OTP Number cannot be Empty` }]}
                                validateFirst={true}
                            >
                                <Input placeholder="Please enter OTP Number" className="formInput" />
                            </Form.Item>
                        </Col>
                        <Col className="actionBtn" xs={{ span: 24 }}>
                            <Button
                                type="primary submit"
                                htmlType="submit"
                                loading={isLoading}
                            // disabled={!checkedStatus ? true : false}
                            >
                                {!isLoading ? 'Verify OTP' : 'Loading...'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
    </div>;

    if (isKycVerified) return <div className=''>
        <div className="isKycVerified">
            <Image
                src={Check}
                preview={false}
            />
            <div className='kycSuccess'>
                {Object.keys(verifiedKyc).map((val) => {
                    return <div>
                        {val === 'isKycCompleted' ? <h1>KYC verified successfully</h1> : null}
                        {val === 'isAadhaarVerified' ? 'Your Aadhar is verified' : null}
                        {val === 'isCinVerified' ? 'Your Cin number is verified' : null}
                        {val === 'isDrivingLicenseVerified' ? 'Your Driving License is verified' : null}
                        {val === 'isGstinVerified' ? 'Your GSTIN Number is verified' : null}
                        {val === 'isPanVerified' ? 'Your PAN Number is verified' : null}
                        {val === 'isPassportVerified' ? 'Your Passport is verified' : null}
                        {val === 'isVoterVerified' ? 'Your Voter ID is verified' : null}
                    </div>
                })}
                <p>Thank you for your support, we have successfuly verified your KYC.</p>
            </div>
        </div>
    </div>;

    if (kycStatusLoading) return <LoadingView message="Loading..." />;

    return (
        <div className="container ">
            <div className="headingContainer">
                <div className="headingTitle"><h3>KYC</h3></div>
                <div className="headingButton">

                </div>
            </div>
            <Form
                form={form}
                layout="vertical"
                onFinish={saveKfc}
            >
                <div
                    className="formItems">
                    <div className='kycCard'>
                        <Row className='' gutter={16}>
                            <Col className="gutter-row" xs={{ span: 24 }}>
                                <Form.Item
                                    label="Document Type"
                                    className="formLabel"
                                    // name="documentType"
                                    rules={[{ required: true, message: 'Please select document type' }]}
                                >
                                    <Select value={documentType}
                                        onChange={(value) => setDocumentType(value)} placeholder="Please select document type">

                                        {documentOption.map((opt, i) => {
                                            return <Option key={i} value={opt.value}>{opt.label}</Option>
                                        })}

                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* {documentType === 'drivingLicence' ?
                                <Col className="gutter-row" xs={{ span: 24 }}>
                                    <Form.Item
                                        label="Date of birth"
                                        name="dob"
                                        validateFirst={true}
                                        rules={[{ required: true, message: "Please enter your D.O.B!" }]}
                                    >
                                        <DatePicker
                                            style={{ "width": "100%" }}
                                            inputReadOnly={true}
                                            defaultPickerValue={moment().subtract(18, "years")}
                                            disabledDate={(current) => {
                                                return current > moment().subtract(18, "years");
                                            }}
                                            format="DD-MM-YYYY"
                                        />
                                    </Form.Item>
                                </Col>
                                : null} */}
                            {/* {documentType === 'CIN' ?
                                <Col className="gutter-row" xs={{ span: 24 }}>
                                    <Form.Item
                                        label="Company Name"
                                        name="docName"
                                        rules={[{ required: true, message: `Company Name cannot be Empty` }]}
                                        validateFirst={true}
                                    >
                                        <Input placeholder="Please enter Company Name" className="formInput" />
                                    </Form.Item>
                                </Col>
                                : null} */}
                            {(documentType === 'voterId' || documentType === 'GSTIN' || documentType === 'CIN' ||documentType === "businessPan") && <Col className="gutter-row" xs={{ span: 24 }}>
                                <Form.Item
                                    label={kycValue?.label}
                                    name='docNumber'
                                    className="formLabel"
                                    rules={[
                                        { required: true, message: `${kycValue?.label} cannot be Empty` },
                                        () => ({
                                            validator(_, value) {
                                                if (!validation.test(value)) {
                                                    return Promise.reject(`Please Enter Valid ${kycValue?.label}`);
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    validateFirst={true}
                                >
                                    <Input placeholder="Please enter document" className="formInput" />
                                </Form.Item>
                            </Col>}
                            {/* {documentType === 'panCard' ?
                                <>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Full Name"
                                            name="fullName"
                                            rules={[{ required: true, message: `Full Name cannot be Empty` }]}
                                            validateFirst={true}
                                        >
                                            <Input placeholder="Exact Name as PAN" className="formInput" />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Date of birth"
                                            name="dob"
                                            validateFirst={true}
                                            rules={[{ required: true, message: "Please enter your D.O.B!" }]}
                                        >
                                            <DatePicker
                                                style={{ "width": "100%" }}
                                                inputReadOnly={true}
                                                defaultPickerValue={moment().subtract(18, "years")}
                                                disabledDate={(current) => {
                                                    return current > moment().subtract(18, "years");
                                                }}
                                                format="DD-MM-YYYY"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Mobile Number"
                                            name="mobileNumber"
                                            rules={[
                                                { required: true, message: `Mobile Number cannot be Empty` },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!/^(?:(?:\+|0{0,2})91(\s*[ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/.test(value)) {
                                                            return Promise.reject(`Please Enter Valid Mobile Number`);
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                            validateFirst={true}
                                        >
                                            <InputNumber type="number" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </> : null
                            } */}
                            {/* {documentType === 'passport' ?
                                <>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Surname"
                                            name="surname"
                                            rules={[{ required: true, message: `Surname cannot be Empty` }]}
                                            validateFirst={true}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="First Name"
                                            name="firstName"
                                            rules={[{ required: true, message: `First Name cannot be Empty` }]}
                                            validateFirst={true}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Gender"
                                            name="gender"
                                            valuePropName="option"
                                            rules={[{ required: true, message: "Gender cannot be Empty" }]}
                                        >
                                            <Select
                                                placeholder="Please Select gender"
                                                style={{ width: "100%" }}
                                                className="kyc-verification"
                                            >
                                                <Select.Option value="MALE">Male</Select.Option>
                                                <Select.Option value="FEMALE">Female</Select.Option>
                                                <Select.Option value="PREFER_NOT_TO_SAY">Prefer not to say</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Country Code"
                                            name="countryCode"
                                            rules={[{ required: true, message: `Country Code cannot be Empty` }]}
                                            validateFirst={true}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Date of birth"
                                            name="dateOfBirth"
                                            validateFirst={true}
                                            rules={[{ required: true, message: "Please enter your Date of birth" }]}
                                        >
                                            <DatePicker
                                                inputReadOnly={true}
                                                format="DD-MM-YYYY"
                                                style={{ "width": "100%" }}
                                                defaultPickerValue={moment().subtract(18, "years")}
                                                disabledDate={(current) => {
                                                    return current > moment().subtract(18, "years");
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Passport Type"
                                            name="passportType"
                                            valuePropName="option"
                                            rules={[{ required: true, message: "Choose passport type" }]}
                                        >
                                            <Select
                                                placeholder="Please Select passport type"
                                                style={{ width: "100%" }}
                                                className="kyc-verification"
                                            >
                                                <Select.Option value="Personal">Personal</Select.Option>
                                                <Select.Option value="Service">Service</Select.Option>
                                                <Select.Option value="Diplomatic">Diplomatic</Select.Option>

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="Date of Expiry"
                                            name="dateOfExpiry"
                                            validateFirst={true}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your Expiry date",
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                style={{ "width": "100%" }}
                                                format="DD-MM-YYYY"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="MRZ 1"
                                            name="mrz1"
                                            rules={[
                                                { required: true, message: `mrz 1 cannot be Empty` },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!/^[A-Z0-9<]{2}[A-Z<]{3}[A-Z0-9<]{39}$/.test(value)) {
                                                            return Promise.reject(`Please Enter Valid mrz 1 number`);
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                            validateFirst={true}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }}>
                                        <Form.Item
                                            label="MRZ 2"
                                            name="mrz2"
                                            rules={[
                                                { required: true, message: `mrz 2 cannot be Empty` },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!/^[A-Z0-9<]{9}[0-9]{1}[A-Z<]{3}[0-9]{6}[0-9]{1}[FM<]{1}[0-9]{6}[0-9]{1}[A-Z0-9<]{14}[0-9]{1}[0-9]{1}$/.test(value)) {
                                                            return Promise.reject(`Please Enter Valid mrz 2 number`);
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                            validateFirst={true}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </> : null} */}
                            <Col className="gutter-row mt-2" xs={{ span: 24 }}>
                                <Form.Item
                                    name="termsandconditions"
                                    label="Terms and Conditions"
                                    rules={[{ required: false, message: 'Please agree terms and conditions' }]}
                                >
                                    <Checkbox onChange={() => setCheckedStatus(!checkedStatus)} checked={checkedStatus ? true : false}>

                                    </Checkbox>  Hello, we collect this personal information from you to verify,
                                    authenticate your registration with us in order to provide you the applicable services we offer in our Website.
                                    By providing the required details here, You are hereby expressly giving us your free consent to process your personal information.
                                    We also recommend you read our <span onClick={() => showModal('TermsOfService')} className='kycPolicyLink'>Terms of Service</span> and <span onClick={() => showModal('PrivacyPolicy')} className='kycPolicyLink'>Privacy Notice</span>.
                                </Form.Item>
                            </Col>


                            <Col className="actionBtn" xs={{ span: 24 }}>
                                <Button
                                    type="primary submit"
                                    htmlType="submit"
                                    disabled={!checkedStatus ? true : false}
                                    loading={isLoading}
                                >
                                    {!isLoading ? 'Apply' : "Loading..."}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
            <TermsAndPrivacyModal
                modalValue={modalValue}
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </div>
    )
}
