import { API_URL } from "./http";

const addJobs = () => `${API_URL}/employers/current/jobs`;
const getJobsById = (jobId) => `${API_URL}/employers/current/jobs/${jobId}`;
const updateJobsById = (jobId) => `${API_URL}/employers/current/jobs/${jobId}`;
const getResumeDetails = (studentId,jobId) => `${API_URL}/employers/current/students/${studentId}/resume/${jobId}`;
const jobsApplicantsList = (jobId) => `${API_URL}/employers/current/jobs/${jobId}/students`;
const jobsApplicantsListByStatus = (jobId, jobStatus, pageNumber) => `${API_URL}/employers/current/jobs/${jobId}/students?status=${jobStatus}&page=${pageNumber}&size=20`;

// const getAllCandidates = (jobId) => `${API_URL}/employers/current/jobs/${jobId}/related-students`;
const getAllCandidates = (jobId, pageNumber, pageSize, jobSeekerName, filterValue) => `${API_URL}/employers/current/jobs/${jobId}/related-students?pageNumber=${pageNumber}&pageSize=${pageSize}${jobSeekerName}${filterValue}`;
const getAllJobsByStatus = (jobStatus, pageNumber) => `${API_URL}/employers/current/jobs?status=${jobStatus}&page=${pageNumber}&size=20`;
const getJobByStatus = (jobStatus) => `${API_URL}/employers/current/jobs?status=${jobStatus}`;
const updateJobsStatus = (jobId) => `${API_URL}/employers/current/jobs/${jobId}/status`;
const getJobsByApplicationStatus = (stdId, userId) => `${API_URL}/employers/current/jobs/${stdId}/students/${userId}/application`
const addStarRating = (stdId, userId) => `${API_URL}/employers/current/jobs/${stdId}/students/${userId}/rate`
const getCandidate = (filterValue, city, page, size) => `${API_URL}/employers/current/students?${filterValue}${city}page=${page}&size=${size}`

// Jobs Question
const getJobQuestion = (jobId) => `${API_URL}/employers/current/jobs/${jobId}/assessment-questions`
const updateJobQuestion = (jobId, questionId) => `${API_URL}/employers/current/jobs/${jobId}/assessment-questions/${questionId}`
const createJobQuestion = (jobId) => `${API_URL}/employers/current/jobs/${jobId}/assessment-questions`
const getQuestionById = (jobId, questionId) => `${API_URL}/employers/current/jobs/${jobId}/assessment-questions/${questionId}`
const deleteQuestion = (jobId, questionId) => `${API_URL}/employers/current/jobs/${jobId}/delete-assessment-questions/${questionId}`
const getStudentAssessment = (jobId, studentId) => `${API_URL}/employers/current/jobs/${jobId}/assessment-details/${studentId}`
// delete all the question 
const deleteAllQuestion = (jobId) => `${API_URL}/employers/current/jobs/delete-all-assessment-questions/${jobId}`

// student job histroy



const candidateNotify = (jobId, userId) => `${API_URL}/employers/current/jobs/${jobId}/students/${userId}/recommend`;
const getEmployerProfile = () => `${API_URL}/employers/current/profile`;
const UpdateEmployerProfile = () => `${API_URL}/employers/current/profile`;

const updateRestoreId = () => `${API_URL}/fresh-chat/restore-id`

const getAllJobs = (jobStatus) => `${API_URL}/employer/current/jobs?status=${jobStatus}`;  //Not call anywhere
const getApplicantStudents = (jobId) => `${API_URL}/jobs/${jobId}/students`;  //Not call anywhere

const generateExperienceLetter = (jobId, studentId) => `${API_URL}/employers/current/jobs/${jobId}/experience-letter-template/${studentId}`;
const generateExperienceLetterPost = (jobId, studentId) => `${API_URL}/employers/current/jobs/${jobId}/experience-letter/${studentId}`;

// city API's
const getAllCities = () => `${API_URL}/cities`;
const getRoleBasedCities = (user) => `${API_URL}/users/cities?role=${user}`;
const getJobsBasedCities = () => `${API_URL}/jobs/cities`;

// AuthBridge API'S
// const voterIdVerify = () => `${API_URL}/auth-bridge/voter/verify`;
// const passportVerify = () => `${API_URL}/auth-bridge/passport/verify`;
// const drivingLicenseVerify = () => `${API_URL}/auth-bridge/driving-license/verify`;
// const panSendOtp = () => `${API_URL}/auth-bridge/pan/send-otp`;
// const panOtpVerify = () => `${API_URL}/auth-bridge/pan/verify-otp`;
// const aadhaarSendOtp = () => `${API_URL}/auth-bridge/aadhaar/send-otp`;
// const aadhaarOtpVerify = () => `${API_URL}/auth-bridge/aadhaar/verify-otp`;
// const gstinVerify = () => `${API_URL}/auth-bridge/gstin/verify`;
// const cinVerify = () => `${API_URL}/auth-bridge/cin/verify`;

const voterIdVerify = () => `${API_URL}/decentro/validate/voter`;
// const passportVerify = () => `${API_URL}/auth-bridge/passport/verify`;
const drivingLicenseVerify = () => `${API_URL}/decentro/validate/digilocker/driving-license`;
// const panSendOtp = () => `${API_URL}/auth-bridge/pan/send-otp`;
const panVerify = () => `${API_URL}/decentro/validate/digilocker/pan`;
const businessPanVerify = () => `${API_URL}/decentro/validate/business-pan`;
// const aadhaarSendOtp = () => `${API_URL}/auth-bridge/aadhaar/send-otp`;
const aadhaarVerify = () => `${API_URL}/decentro/validate/digilocker/aadhaar`;
const gstinVerify = () => `${API_URL}/decentro/validate/gstin`;
const cinVerify = () => `${API_URL}/decentro/validate/cin`;
//ai api
const getDescription=()=>`${API_URL}/employers/current/generate-job-description`
const getQuestionsAi=()=>`${API_URL}/employers/current/generate-job-assessment-mcq`
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addJobs,
  getAllJobs,
  getJobsById,
  updateJobsById,
  getApplicantStudents,
  getAllJobsByStatus,
  updateJobsStatus,
  getResumeDetails,
  getAllCandidates,
  jobsApplicantsList,
  candidateNotify,
  getEmployerProfile,
  UpdateEmployerProfile,
  getJobsByApplicationStatus,
  jobsApplicantsListByStatus,
  addStarRating,
  getCandidate,
  updateRestoreId,
  getJobByStatus,
  voterIdVerify,
  // passportVerify,
  // panSendOtp,
  businessPanVerify,
  panVerify,
  gstinVerify,
  cinVerify,
  // aadhaarSendOtp,
  drivingLicenseVerify,
  aadhaarVerify,
  getAllCities,
  getRoleBasedCities,
  getJobsBasedCities,
  getJobQuestion,
  updateJobQuestion,
  createJobQuestion,
  getQuestionById,
  deleteQuestion,
  getStudentAssessment,
  deleteAllQuestion,
  generateExperienceLetter,
  generateExperienceLetterPost,
  getDescription,
  getQuestionsAi
};
