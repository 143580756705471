import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Select, Modal } from 'antd';
import toastr from "toastr";
import { useParams } from "react-router";
import { JobsServiceApi } from "../../api";
import {
    getRequest,
    patchRequest,
} from "../../api/http";

import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const questionOptions = [
    { name: "Option A", value: "OPTION_A" },
    { name: "Option B", value: "OPTION_B" },
    { name: "Option C", value: "OPTION_C" },
    { name: "Option D", value: "OPTION_D" },
]

const UpdateQuestionModal = ({ updateQuestionModal, setUpdateQuestionModal, setQuestionData,
    questionData, questionEditData, setQuestionEditData, fetchQuestionByJob }) => {
    const { jobId } = useParams();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [loading, setloading] = useState(true)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const handleEditorStateChange = (val) => {
        setEditorState(val)
    }

    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        // setEditorState(editorState);
        return editorState;
      };

    const emptyQuestionForm = () => {
        form.setFields([
            { name: "correctAnswer", value: "", errors: "" },
            { name: "question", value: "", errors: "" },
            { name: "optionA", value: "", errors: "" },
            { name: "optionB", value: "", errors: "" },
            { name: "optionC", value: "", errors: "" },
            { name: "optionD", value: "", errors: "" },
        ])
    }

    const getQuestion = async () => {
        try {
            const response = await getRequest(JobsServiceApi.getQuestionById(jobId, questionEditData?.id));
            if (response) {
                form.setFieldsValue({
                    correctAnswer: response?.correctAnswer,
                    optionA: response?.optionA,
                    optionB: response?.optionB,
                    optionC: response?.optionC,
                    optionD: response?.optionD,
                    question: response?.question
                });
                setEditorState(htmlToDraftBlocks(response?.question))
                setloading(false)
            }
        } catch (error) {
            setloading(false)
            toastr.error(error.message);
        }

    }

    useEffect(() => {
        if (questionEditData?.id && jobId) {
            getQuestion()
        } else {
            if (questionEditData?.id) {
                form.setFieldsValue({
                    correctAnswer: questionEditData?.correctAnswer,
                    optionA: questionEditData?.optionA,
                    optionB: questionEditData?.optionB,
                    optionC: questionEditData?.optionC,
                    optionD: questionEditData?.optionD,
                    question: questionEditData?.question
                });
                setEditorState(htmlToDraftBlocks(questionEditData?.question))
                setloading(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionEditData?.id]);


    const handleQuestion = async (values) => {
        if (jobId) {
            const editData = {
                ...values,
                question: convertToHTML(editorState.getCurrentContent())
            }
            setloading(true)
            try {
                const response = await patchRequest(JobsServiceApi.updateJobQuestion(jobId, questionEditData?.id), editData);
            if (response) {
                toastr.success("Question Updated Successfully");
                fetchQuestionByJob(jobId)
                setQuestionEditData({})
                emptyQuestionForm()
                setloading(false)
            }
            } catch (error) {
                toastr.success(error?.message);
                setQuestionEditData({})
                emptyQuestionForm()
                setloading(false)
            }
            
        } else {
            let res = questionData?.map((item) => {
                if (item?.id === questionEditData?.id) {
                    return { ...values, question: convertToHTML(editorState.getCurrentContent()), id: item?.id }
                } else {
                    return item
                }
            })
            setQuestionData(res)
            setQuestionEditData({})
            emptyQuestionForm()
        }
        setUpdateQuestionModal(!updateQuestionModal)
    }

    const handleCloseQuestionModal = () => {
        emptyQuestionForm()
        setQuestionEditData({})
        setUpdateQuestionModal(!updateQuestionModal)
    }

    return (
        <Modal
            title=""
            visible={updateQuestionModal}
            onOk={handleCloseQuestionModal}
            onCancel={handleCloseQuestionModal}
            width={800}
            footer={null}>
                <Form
                    form={form}
                    layout="vertical"
                    name="basic"
                    onFinish={handleQuestion}
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            <Form.Item
                             label="Question"
                             name="question"
                             rules={[{ required: true, message: 'Please enter Question' },
                             () => ({
                                validator(_, value) {
                                    if (!editorState.getCurrentContent().hasText()) {
                                        return Promise.reject(`Please enter Question`);
                                    }
                                    return Promise.resolve();
                                },
                            }),
                            ]}
                            validateFirst={true}
                            >
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    onEditorStateChange={handleEditorStateChange}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            <Form.Item
                                label="Option A"
                                name="optionA"
                                rules={[{ required: true, message: 'Please enter option A' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            <Form.Item
                                label="Option B"
                                name="optionB"
                                rules={[{ required: true, message: 'Please enter option B' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            <Form.Item
                                label="Option C"
                                name="optionC"
                                rules={[{ required: true, message: 'Please enter option C' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            <Form.Item
                                label="Option D"
                                name="optionD"
                                rules={[{ required: true, message: 'Please enter option D' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            <Form.Item
                                label="Select answer"
                                name="correctAnswer"
                                rules={[{ required: true, message: 'Please select Answer' }]}
                            >
                                <Select
                                    placeholder="Please select Answer"
                                    allowClear
                                >
                                    {questionOptions?.map(opt => (
                                        <Option value={opt.value}>{opt.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }} className="create-question-btn">
                            <Button type="primary submit" htmlType="submit" disabled={loading} loading={loading} >
                                Update Question
                            </Button>
                        </Col>
                    </Row>
                </Form>
        </Modal>
    );
};

export default UpdateQuestionModal;
