/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Input, Form, Image } from 'antd';
import toastr from 'toastr';
import { UserServiceApi } from '../../api';
import { postRequest } from '../../api/http';
// import { LoadingView } from '../common'
import Lady from "../../assets/images/lady.png";
import './index.scss';


const ResetPassword = () => {
  // const [loading, setloading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const onFinish = async (values) => {
    setIsBtnLoading(true)
    try {
      const response = await postRequest(UserServiceApi.resetPassword(), values);
      setIsBtnLoading(false)
      toastr.success('Successfully Submitted');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.clear();
      window.location.href = '/login';
      window.fcWidget.destroy();
    } catch (e) {
      toastr.error(e.message);
      setIsBtnLoading(false)
    }
  };

  const onFinishFailed = (errorInfo) => {
    // toastr.error(errorInfo);
  };

  function Imagelady() {
    return (
      <Image
        src={Lady}
        preview={false}
      />
    );
  }

  // if (loading) return <LoadingView message="Loading..." />;
  return (
    <div className='resetPassword'>
      <div className="forgetPassword">
        {localStorage.getItem('role') === 'PLACEMENT_OFFICER' && (
          <div style={{textAlign:'right'}}>
             <Button type='primary' onClick={() => window.history.back()} >Back</Button>
          </div>
         
        )}

        <div className='forgetPasswordRow'>
          <div className='forgetPasswordCol imageLady'>
            <Imagelady />
          </div>
          <div className='forgetPasswordCol'>
            <h2>Reset<br />Password?</h2>
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="currentPassword"
                label="Old Password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter current password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter new password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={['newPassword']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please enter confirm password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <br />
              <Form.Item className="resetBtn">
                <Button type="primary" htmlType="submit" loading={isBtnLoading}>
                  {!isBtnLoading ? 'Change Password' : 'Loading...'}
                </Button>
              </Form.Item>
            </Form>

          </div>
        </div>
      </div>
    </div >
  );
}

export default ResetPassword;
