import { API_URL } from "./http";

const addEmployer = () => `${API_URL}/employers`;

const getUserDetails = () => `${API_URL}/me`;

const resetPassword = () => `${API_URL}/reset-password`;

const forgotPassword = () => `${API_URL}/forgot-password`;

const updateResetPassword = (token) => `${API_URL}/forgot-password/reset?token=${token}`;

const getProfileImage = () => `${API_URL}/profile-picture`;

const getAllSubscriptionPlans = () => `${API_URL}/subscriptions`;

const getCurrentSubscriptionPlan = () => `${API_URL}/employers/current/subscriptions`;

const UpdateCurrentSubscriptionPlan = (planID) => `${API_URL}/employers/current/subscriptions/${planID}`;

const getAllJobsCategories = () => `${API_URL}/categories`;

const getUsersKycStatus = () => `${API_URL}/users/current/kyc-details`;

const verifyEmail =(token)=>`${API_URL}/verify-email?token=${token}`;

const getBanner=()=>`${API_URL}/employers/current/subscription/seasonal-discount`
const getEventPlans=()=>`${API_URL}/employers/current/active-event-subscriptions`
const getActiveGeneralPlan=()=>`${API_URL}/employers/current/active-general-subscription`
const getPromos=()=>`${API_URL}/employers/current/subscription/promo-codes`
const getTotalAmount=(subId,discountId)=>`${API_URL}/employers/current/subscription/${subId}/apply/discount/${discountId}`
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addEmployer,
  getUserDetails,
  resetPassword,
  forgotPassword,
  updateResetPassword,
  getProfileImage,
  getAllSubscriptionPlans,
  getCurrentSubscriptionPlan,
  UpdateCurrentSubscriptionPlan,
  getAllJobsCategories,
  getUsersKycStatus,
  verifyEmail,
  getBanner,
  getEventPlans,
  getActiveGeneralPlan,
  getPromos,
  getTotalAmount
};
