/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Input, Form, Image } from 'antd';
import toastr from 'toastr';
import { postRequest } from '../../api/http';
import { Link, useNavigate } from "react-router-dom";
import { UserServiceApi } from '../../api';
import { LoadingView } from '../common'
import HeaderWithOutAuth from "../header/headerWithOutAuth"
import Lady from "../../assets/images/lady.png";
import './index.scss';


const ForgetPassword = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setloading] = useState(false);
  const [resetPwdPopup, setresetPwdPopup] = useState(false);
  const navigate = useNavigate();
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    types: {
      email: '${label} is not a valid email!',
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const onFinish = async (values) => {
    setloading(true);
    try {
      const response = await postRequest(UserServiceApi.forgotPassword(), values)
      setloading(false);
      toastr.success('Email sent successfully');
      setresetPwdPopup(true)
    } catch (error) {
      setloading(false);
      toastr.error(error.message)
    }
  };

  const onFinishFailed = (errorInfo) => {
    toastr.error('Please enter email address');
  };

  function Imagelady() {
    return (
      <Image
        src={Lady}
        preview={false}
      />
    );
  }
  // if (loading) return <LoadingView message="Loading..." />;
  return (
    <React.Fragment>
      <HeaderWithOutAuth />
      <div className="forgetPassword">

        <div className='forgetPasswordRow'>
          <div className='forgetPasswordColImg imageLady'>
            <Imagelady />
          </div>

          <div className='forgetPasswordCol'>
            {resetPwdPopup ?
              <div className='resetPopup'>
                <h2>Reset your password</h2>
                <p>
                  Check your email for a link to reset your password.
                </p>
                <Button onClick={() => navigate("/login")} type="primary" className="forgetPasswordBtn">
                  Return to Login
                </Button>
              </div> :
              <>
                <h2>Forgot Password?</h2>
                <p>Enter the email address associated with your Account</p>
                <Form
                  // layout="inline"
                  name="nest-messages"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  validateMessages={validateMessages}>
                  <Form.Item
                    name='email'
                    rules={[{ type: 'email', required: true, message: 'Please enter email address' }]}
                    className="forgetPwdRow"
                  >
                    <Input placeholder="Enter email address" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="forgetPasswordBtn"
                    loading={loading}
                    >
                     {!loading ? 'Request' : 'Loading...'}
                    </Button>
                  </Form.Item>
                </Form>
                <div className="backToLogin">
                  <p>Click here to <Link className="backToLoginLink" to="/login"> Login</Link></p>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword;
