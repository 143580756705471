import React from "react";
import { Button, Col, Modal, Row } from 'antd';

const ViewQuestionModal = ({ viewQuestionModal, setViewQuestionModal, questionEditData, setQuestionEditData, updateQuestionModal, setUpdateQuestionModal }) => {

    const handleCloseQuestionModal = () => {
        setViewQuestionModal(!viewQuestionModal)
    }

    const handleEditQuestion = () => {
        setQuestionEditData(questionEditData)
        setUpdateQuestionModal(!updateQuestionModal)
        setViewQuestionModal(!viewQuestionModal)
    }

    return (
        <Modal
            title={"View Question"}
            visible={viewQuestionModal}
            onOk={handleCloseQuestionModal}
            onCancel={handleCloseQuestionModal}
            width={800}
            footer={
                <div>
                    <Button onClick={handleEditQuestion} type="primary">Edit Question</Button>
                    <Button onClick={handleCloseQuestionModal} type="secondary">Close</Button>
                </div>
            }>
            <div className="view-question-modal">
                <Row gutter={16} className="view-question">
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 5}}>
                        <div className="view-question-heading">Question</div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 19 }}>
                        <div
                            dangerouslySetInnerHTML={{ __html: questionEditData?.question }}
                        />
                    </Col>
                </Row>
                <Row gutter={16} className="view-question">
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 5}}>
                        <div className="view-question-heading">Option A</div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 19 }}>
                        {questionEditData?.optionA}
                    </Col>
                </Row>
                <Row gutter={16} className="view-question">
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 5}}>
                        <div className="view-question-heading">Option B</div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 19 }}>
                        {questionEditData?.optionB}
                    </Col>
                </Row>
                <Row gutter={16} className="view-question">
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 5}}>
                        <div className="view-question-heading">Option C</div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 19 }}>
                        {questionEditData?.optionC}
                    </Col>
                </Row>
                <Row gutter={16} className="view-question">
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 5}}>
                        <div className="view-question-heading">Option D</div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 19 }}>
                        {questionEditData?.optionD}
                    </Col>
                </Row>
                <Row gutter={16} className="view-question">
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 5}}>
                        <div className="view-question-heading">Correct Answer</div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 19 }}>
                        <span className="question-correct-answer">{questionEditData?.correctAnswer?.replace("_", " ")}</span> is the correct answer.
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default ViewQuestionModal;
