import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import toastr from 'toastr';
import { LoadingView } from '../common';
import { JobsServiceApi } from '../../api';
import { getRequest, putRequest } from '../../api/http';
import { Image, Pagination } from 'antd';
import OfficeIcon from '../../assets/images/icons/office.svg';
import CalendarIcon from '../../assets/images/icons/calendar.svg';
import LocationIcon from '../../assets/images/icons/location.svg';
import ErrorIcon from '../../assets/images/icons/Pagenotfound.svg';
import EventIcon from '../../assets/images/icons/eventbased.svg'
import HostIcon from '../../assets/images/icons/host.svg'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "./index.scss";
import {  DownloadOutlined } from '@ant-design/icons';

export default function JobCard({ tabkey, jobId, status, setCurrentJob,studentList }) {
    let splitUrl = window.location.pathname.split('/');
    
    let splitUrlValue = splitUrl[1];
    const [jobsList, setJobsList] = useState([]);
    const [loading, setloading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tabsKey, setTabsKey] = useState('PUBLISH');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [jobName,setJobName]=useState(null)
    const updateJobs = async (jobsId, jobStatus) => {
        console.log('jobId', jobsId)
        console.log('jobStatus', jobStatus)
        setloading(true);
        try {
            const response = await putRequest(JobsServiceApi.updateJobsStatus(jobsId), { "status": jobStatus });
            setTabsKey(tabsKey);
            setloading(false)
            if (response.status === 'PUBLISH') {
                toastr.success("Job Published Successfully");
            } else if (response.status === 'ON_HOLD') {
                toastr.success("Job put on Hold successfully");
            } else if (response.status === 'ARCHIVE') {
                toastr.success("Job Archived Successfully");
            } else if (response.status === 'DRAFT') {
                toastr.success("Job Draft Successfully");
            }
        } catch (err) {
            toastr.error(err.message);
            setloading(false)
        }
    }
    const handleDownloadPDF = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const doc = new jsPDF();
        const tableColumn = ["S.No", "Name", "Mobile Number","Skills"];
        const tableRows = [];
        console.log(studentList,'studentdata')
        studentList.forEach((student, index) => {
          const studentSkills = student.skills ? student.skills.slice(0, 4).join(', ') : 'No Skills';
          const studentData = [
            index + 1,
            student.name,
            student.resumeViewed ? student.phone : '       -',
            studentSkills,
          ];
          tableRows.push(studentData);
        });
        doc.setFontSize(16);
        doc.setTextColor(40);
        doc.text(`List of students applied for ${jobName}`, 14, 16);
        
        doc.autoTable({
          startY: 25,
          head: [tableColumn],
          body: tableRows,
        });
    
        doc.save(`${jobName}_Appliedlist.pdf`);
      };
    const fetchJobsList = async (jobStatus, pageNumber) => {
        setTotalElements('')
        try {
            const response = await getRequest(JobsServiceApi.getAllJobsByStatus(jobStatus, pageNumber));
            setloading(false)
            setJobsList(response.content)
            // let resulMultiply = multiply(response.numberOfElements, response.totalPages);
            setTotalElements(response.totalElements)
        } catch (err) {
            toastr.error(err.message);
            setloading(false)
            setJobsList([])
        }
    }
    const fetchJobById = async (currentJobId) => {
        try {
            setIsLoading(true)
            const response = await getRequest(JobsServiceApi.getJobsById(currentJobId));
            if (response) {
                setCurrentJob(response)
                setJobName(response.title)
                setJobsList([response])
                setIsLoading(false)
            }
        } catch (e) {
            toastr.error(e.message);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (tabkey === "PUBLISH") {
            fetchJobsList('PUBLISH', '0');
        } else if (tabkey === "ON_HOLD") {
            fetchJobsList('ON_HOLD', '0');
        } else if (tabkey === "ARCHIVE") {
            fetchJobsList('ARCHIVE', '0');
        } else if (tabkey === "DRAFT") {
            fetchJobsList('DRAFT', '0');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabkey]);

    useEffect(() => {
        if (jobId) {
            fetchJobById(jobId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobId]);


    function onChange(pageNumber) {
        setCurrentPage(pageNumber)
        if (tabkey === "PUBLISH") {
            fetchJobsList('PUBLISH', pageNumber - 1);
        } else if (tabkey === "ON_HOLD") {
            fetchJobsList('ON_HOLD', pageNumber - 1);
        } else if (tabkey === "ARCHIVE") {
            fetchJobsList('ARCHIVE', pageNumber - 1);
        } else if (tabkey === "DRAFT") {
            fetchJobsList('DRAFT', pageNumber - 1);
        }
    }

    if (loading) return <LoadingView message="Loading..." />;
    if (isLoading) return <LoadingView message="Loading..." />;
    return (
        <div className="jobsCardContainer">
            {jobsList.length === 0 ?
                <div className='errorMsg'>
                    <Image src={ErrorIcon} preview={false} />
                    {tabsKey === 'PUBLISH' ? (<h4>No Active Jobs</h4>) : null}
                    {tabsKey === 'ON_HOLD' ? (<h4>No Jobs on Hold</h4>) : null}
                    {tabsKey === 'ARCHIVE' ? (<h4>No Archive Jobs</h4>) : null}
                    {tabsKey === 'DRAFT' ? (<h4>No Draft Jobs</h4>) : null}
                </div> :
                (jobsList || []).map((job, i) => (
                    <div key={i} className="jobsRow">
                        <Link
                            to={`/jobs-details/${job.id}`}
                            state={{ jobTitle: job.title, jobDetails: job }}>
                            <div className="jobsHead">
                                <div className="jobsHeadCol">
                                   {splitUrlValue==="applicant" ? (
                                   <><div className='download-icon'>
                                    <h3>{job.title}
                                        {
                                            // <small>{job.company}</small>
                                        }
                                    </h3>
                                    <DownloadOutlined style={{fontSize:'30px',marginRight:'10px',color:'#ed9f0b'}} onClick={(e) => handleDownloadPDF(e)} disabled={studentList<1}/> 
                                    </div>
                                    </>):(<h3>{job.title}
                                        {
                                            // <small>{job.company}</small>
                                        }
                                    </h3>)}
                                    <ul>
                                        <li>
                                            <span><Image src={OfficeIcon} preview={false} /></span>
                                            <span className='JobInfo'>
                                                <span className='infoNameType'>Working Type</span>
                                                <span className='infoNameValue'>{job.workingType === "WORK_FROM_OFFICE" ? "Work from office" : job.workingType === "WORK_FROM_HOME" ? "Work from home" : 'Flexible'}</span>
                                            </span>
                                        </li>
                                        <li>
                                            <span><Image src={LocationIcon} preview={false} /></span>
                                            <span className='JobInfo'>
                                                <span className='infoNameType'>Location</span>
                                                <span className='infoNameValue'><span>{job.city !== null ? job.city : '-'}</span></span>
                                            </span>
                                        </li>
                                        <li>
                                            <span><Image src={CalendarIcon} preview={false} /></span>
                                            <span className='JobInfo'>
                                                <span className='infoNameType'>Job Submission date</span>
                                                <span className='infoNameValue'><span>{job.deadLine !== null ? (moment(job.deadLine).format("DD-MM-YYYY")) : '-'}</span></span>
                                            </span>
                                        </li>
                                        {job.eventName && (
                                            <li>
                                                <span><Image src={HostIcon} preview={false} /></span>
                                                <span className='JobInfo'>
                                                    <span className='infoNameType'>Host</span>
                                                    <span className='infoNameValue'><span>{job.eventName}</span></span>
                                                </span>
                                            </li>
                                        )}
                                        {job.showIn === 'EVENT' && (
                                            <li>
                                                <div className='tag'>
                                                    <Image src={EventIcon} preview={false} style={{ height: "39px", width: "53px" }} />
                                                </div>
                                            </li>
                                        )}

                                    </ul>
                                </div>
                            </div>
                        </Link>
                        <div className="jobsInfo">
                            <div className={jobId ? 'jobsInfoCol jobsInfoColWidth' : 'jobsInfoCol'}>
                                {job.status === 'PUBLISH' ? (
                                    <ul>
                                        <li>
                                            <Link to={`/applicant/${job.id}`} state={{ jobDetails: job }}>
                                                <span style={splitUrlValue === 'applicant' ? { color: '#ed9f0b' } : null}>Applied</span>
                                                <span style={splitUrlValue === 'applicant' ? { color: '#ed9f0b' } : null}>[{job.applied}]</span></Link>
                                        </li>
                                        <li>
                                            <Link to={`/shortlisted/${job.id}`} state={{ jobDetails: job }}>
                                                <span style={splitUrlValue === 'shortlisted' ? { color: '#ed9f0b' } : null}>Shortlisted</span>
                                                <span style={splitUrlValue === 'shortlisted' ? { color: '#ed9f0b' } : null}>[{job.shortlisted}]</span></Link>
                                        </li>
                                        <li>
                                            <Link to={`/offered/${job.id}`} state={{ jobDetails: job }}>
                                                <span style={splitUrlValue === 'offered' ? { color: '#ed9f0b' } : null}>Offered</span>
                                                <span style={splitUrlValue === 'offered' ? { color: '#ed9f0b' } : null}>[{job.offeredPending}]</span></Link>
                                        </li>
                                        <li>
                                            <Link to={`/rejected/${job.id}`} state={{ jobDetails: job }}>
                                                <span style={splitUrlValue === 'rejected' ? { color: '#ed9f0b' } : null}>Rejected</span>
                                                <span style={splitUrlValue === 'rejected' ? { color: '#ed9f0b' } : null}>[{job.rejected}]</span></Link>
                                        </li>
                                        <li>
                                            <Link to={`/view/${job.id}`} state={{ jobDetails: job }}>
                                                <span style={splitUrlValue === 'view' ? { color: '#ed9f0b' } : null}>Viewed</span>
                                                <span style={splitUrlValue === 'view' ? { color: '#ed9f0b' } : null}>[{job.viewed}]</span></Link>
                                        </li>
                                        <li>
                                            <Link to={`/onHold/${job.id}`} state={{ jobDetails: job }}>
                                                <span style={splitUrlValue === 'onHold' ? { color: '#ed9f0b' } : null}>On Hold</span>
                                                <span style={splitUrlValue === 'onHold' ? { color: '#ed9f0b' } : null}>[{job.inReview}]</span></Link>
                                        </li>
                                        <li>
                                            <Link to={`/offer-accepted/${job.id}`} state={{ jobDetails: job }}>
                                                <span style={splitUrlValue === 'offer-accepted' ? { color: '#ed9f0b' } : null}>Offer Accepted</span>
                                                <span style={splitUrlValue === 'offer-accepted' ? { color: '#ed9f0b' } : null}>[{job.offerAccepted}]</span></Link>
                                        </li>
                                        <li>
                                            <Link to={`/offer-rejected/${job.id}`} state={{ jobDetails: job }}>
                                                <span style={splitUrlValue === 'offer-rejected' ? { color: '#ed9f0b' } : null}>Offer Rejected</span>
                                                <span style={splitUrlValue === 'offer-rejected' ? { color: '#ed9f0b' } : null}>[{job.offerRejected}]</span></Link>
                                        </li>
                                    </ul>
                                ) : null}

                            </div>
                            {jobId ? null :
                                <div className="jobsInfoCol">
                                    {
                                        // DRAFT, PUBLISH, ON_HOLD, ARCHIVE
                                    }
                                    {job.status === 'PUBLISH' ? (
                                        <ul className='buttons'>
                                            <li><Link to={`/jobs/duplicate`} state={{ jobIdForDuplicate: job.id }}><span>Duplicate</span></Link></li>
                                            <li><span onClick={() => updateJobs(job.id, 'ON_HOLD')}>On Hold</span></li>
                                            <li><span onClick={() => updateJobs(job.id, 'ARCHIVE')}>Archive</span></li>
                                        </ul>
                                    ) : null}
                                    {job.status === 'ON_HOLD' ? (
                                        <ul>
                                            <li><span onClick={() => updateJobs(job.id, 'PUBLISH')}>Publish</span></li>
                                            <li><span onClick={() => updateJobs(job.id, 'ARCHIVE')}>Archive</span></li>
                                        </ul>
                                    ) : null}
                                    {
                                        job.status === 'ARCHIVE' ? (
                                            <ul>
                                                <li><Link to={`/jobs/duplicate`} state={{ jobIdForDuplicate: job.id }}><span>Duplicate</span></Link></li>
                                            </ul>
                                        ) : null
                                    }
                                    {job.status === 'DRAFT' ? (
                                        <ul>
                                            <li><Link to={{ pathname: `/jobs/${job.id}` }}><span>Edit</span></Link></li>
                                            <li><span onClick={() => updateJobs(job.id, 'ARCHIVE')}>Archive</span></li>
                                        </ul>
                                    ) : null}
                                </div>
                            }
                        </div>
                    </div>
                ))
            }
            <Pagination
                current={currentPage}
                pageSize={20}
                total={totalElements}
                onChange={onChange}
                className="HomePagination"
            />
        </div>
    )
}
