import React, { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Button } from 'antd';
import JobCard from '../../jobCard';
import StudentListTable from '../../../components/studentListTable'
import './index.scss';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default function Applicant(props) {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [status, setStatus] = useState(false);
  const [currentJob, setCurrentJob] = useState([])
  const [studentList, setStudentList] = useState([])
  console.log(currentJob,'currentJob')
  const OnclickChangeStatus = () => {
    setStatus(!status)
  }
  const setList = (list) =>{
    setStudentList(list)
  }

  const renderJobInfo = () => {
    return (
      <JobCard jobId={jobId} status={status} setCurrentJob={setCurrentJob} studentList={studentList} />
    )
  }

  return (
    <div className="container">
      <div className="headingContainer">
        <div className="headingTitle"><h3>Job Applied</h3></div>
        <div className="headingButton">
          <Button className="btn ant-btn ant-btn-primary" onClick={() => navigate(-1)}>Back</Button>
        </div>
      </div>
      <div className='applicantList'>
        {renderJobInfo()}
        <StudentListTable currentJob={currentJob} jobId={jobId} jobStatus="APPLIED" onClick={() => OnclickChangeStatus()} status={status} setStudentList={(items)=>setList(items)} />
      </div>
    </div>
  );
}
