import { API_URL } from './http';

const getSubscriptionAmount=()=>`${API_URL}/employers/current/custom-subscription-pricings`
const getSubscriptionAmountById=(id)=>`${API_URL}/employers/current/subscription-pricing/${id}`
const BuyCustomSubscription=()=>`${API_URL}/employers/current/custom-subscriptions/065b807b-f343-11ee-acaa-000d3af0c434`

export default {
    getSubscriptionAmount,
    BuyCustomSubscription,
    getSubscriptionAmountById,
}
