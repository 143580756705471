import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import toastr from 'toastr';
import { UserServiceApi } from '../../api';
import { getRequest } from '../../api/http';
import "./index.scss";
import { FileProtectOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export default function SubscriptionHistory() {
    const navigate = useNavigate();
    const [subscriptionHistory, setSubscriptionHistory] = useState([]);

    const fetchAllSubscriptionPlans = async () => {
        try {
            const response = await getRequest(UserServiceApi.getCurrentSubscriptionPlan());
            setSubscriptionHistory(response);
        } catch (e) {
            toastr.error(e.message);
        }
    };

    useEffect(() => {
        fetchAllSubscriptionPlans();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="headingContainer">
                <div className="headingTitle"><h3>Plans & Billing</h3></div>
                <div className="headingButton">
                    <Button className="btn ant-btn ant-btn-primary backBtn" onClick={() => navigate(-1)}>Back</Button>
                </div>
            </div>
            <div className="subscriptionCurrentPlans">
                <div className="subscriptionHistory">
                    <span><FileProtectOutlined /></span>
                    <span>Subscription History</span>
                </div>
                <div className="subscriptionHistory">
                    {
                        (subscriptionHistory || []).map(plan => (
                            <div className="subscriptionCurrentPlanBox histroy">
                                <ul>
                                    <li><strong>{plan.showIn === "EVENT" ? "Event Plan" : "General Plan"}:</strong> {(plan.name).replace('_', ' ')}</li>
                                    {
                                        // <li><strong>Remaining listing :</strong> {plan.noOfListingsLeft !== null ? plan.noOfListingsLeft : '0'} out of {plan.noOfListings}</li>
                                        // <li><strong>Remaining Resume views :</strong>  {plan.noOfResumeViewsLeft !== null ? plan.noOfResumeViewsLeft : '0'} out of  {plan.noOfResumeViews}</li>
                                    }
                                    <li><p className='expireDate'>Your subscription expired in {plan.validityPeriod} Days</p></li>
                                </ul>
                            </div>
                        ))
                    }

                </div>
                <div className="subscriptionHistory">
                    <div className='downloadInvoice'>
                        <a href={subscriptionHistory[0]?.invoiceUrl}>
                            <Button className="btn ant-btn ant-btn-primary" >Download Invoice</Button>
                        </a>
                    </div>
                </div>
            </div>
            <div className='gstInvoice'>
                <p>* Please update GST number if you require GST inovice</p>
            </div>
        </div>
    )
}
