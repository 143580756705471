import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { UserServiceApi } from '../../api';
import { getRequest } from '../../api/http';
import toastr from 'toastr';
function KycStatus() {
    const navigate=useNavigate()
    const getEmployerKycStatus = async () => {
        try {
            const response = await getRequest(UserServiceApi.getUsersKycStatus());
            // console.log(response,'response is this')
            if (response) {
                if (response?.isKycCompleted) {
                    navigate('/KYC',{state:{kycCompleted:false}})
                } else {
                    navigate('/KYC',{state:{kycCompleted:true}})
                }
            }
        } catch (err) {
            toastr.error(err.message);
        }
    }
    useEffect(() => {
        getEmployerKycStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  return (
    <div></div>
  )
}

export default KycStatus