/* global branch */
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import AppMain from "./components/AppMain";
import Login from "./components/login/Login";
import "./assets/styles/main.scss";
import "antd/dist/antd.css";
import ForgetPassword from "./components/forgetPassword";
import EmployerRegister from "./components/employerRegister";
import UpdateResetPassword from "./components/resetPassword/updateResetPassword";
import VerifyEmail from "./components/verifyEmail";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const registerServiceWorker = () => {
      if ("serviceWorker" in navigator) {
        setTimeout(() => {
          navigator.serviceWorker
            .register("/firebase-messaging-sw.js")
            .then((registration) => {
              console.log("Firebase Service Worker registered with scope:", registration.scope);
            })
            .catch((error) => {
              console.error("Error registering Firebase Service Worker:", error);
            });
        }, 3000); // 10 seconds delay
      }
    };

    registerServiceWorker();
  }, []);
  // useEffect(() => {
  //   branch.init(process.env.REACT_APP_BRANCH_KEY, (err, data) => {
  //     if (err) {
  //       console.error('Branch initialization failed', err);
  //     } else {
  //       console.log('Branch initialized', data);

  //       // Handle the deep link data here if needed
  //       if (data && data.data_parsed && data.data_parsed.$canonical_url) {
  //         window.location.href = data.data_parsed.$canonical_url;
  //       }
  //     }
  //   });

  //   return () => {
  //     branch.close();
  //   };
  // }, []);
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/404"
            element={
              <main>
                <h1>404</h1>
                <p>There's nothing here!</p>
              </main>
            }
          />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/employer-registration" element={<EmployerRegister />} />
          <Route path="/forgot-password/:token" element={<UpdateResetPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="*" element={<AppMain />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
