import React from 'react';
import { Modal, Row, Col } from 'antd';
import './EventDetailsModal.scss'; // Import CSS file for styling
import moment from 'moment';


const EventDetailsModal = ({ visible, event, onClose }) => {
  return (
    <Modal
      title={event?.name} // Display event name as modal title
      visible={visible}
      onCancel={onClose}
      footer={null}
      style={{ margin: "-50px auto" }}
      className="event-details-modal" // Apply custom class for styling
    >
      <div className="event-details-content">
        {/* Description */}
        <Row className="event-detail-row">
          <Col span={24}>
            {/* <h3 style={{ color: 'ed9f0b' }}>Description:</h3> */}
            <p >{event?.description}</p>
          </Col>
        </Row>

        {/* Event Details */}
        <Row className="event-detail-row">
          <Col span={24}>
            <h3>Event Details:</h3>
          </Col>
          <Col span={24}>
            <p>
              <span className="event-info-label">Starts from</span> {moment.utc(event?.startDate).local().format('H:mm, MMM D, YYYY')}
            </p>
            <p>
              <span className="event-info-label">Ends on</span> {moment.utc(event?.endDate).local().format('H:mm, MMM D, YYYY')}
            </p>

            <p>

              <span className="event-info-label">Venue:</span> {event?.venue}
            </p>
          </Col>
        </Row>

        {/* Organization */}
        <Row className="event-detail-row">
          <Col span={24}>
            <h3>Organization:</h3>
          </Col>
          <Col span={24}>
            <p><span className="event-info-label">Name:</span> {event?.organization.name}</p>
            <p><span className="event-info-label">Address:</span> {event?.organization.address}</p>
          </Col>
        </Row>

        {/* Subscription Plan Details */}
        <Row className="event-detail-row">
          <Col span={24}>
            <h3>Subscription Plan Details:</h3>
          </Col>
          <Col span={24}>
            <p><span className="event-info-label">Name:</span> {event?.subscriptionPlan?.name}</p>
            <p><span className="event-info-label">Amount:</span> {event?.subscriptionPlan?.price}</p>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EventDetailsModal;
