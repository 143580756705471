
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/images/timbukdo-logo.svg";
import "./index.scss";

function HeaderWithOutAuth() {
  function ImageLogo() {
    return (
      <Logo width={200} />
    );
  }
  return (
    <div className='headerWithOutAuth'>
      <Link to="/login"><ImageLogo /></Link>
    </div>
  );
}

export default HeaderWithOutAuth;
