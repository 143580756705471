import React, { useState, useEffect } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
// import DOMPurify from 'dompurify';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';



const ControlledEditor = ({ sendChildToParent, description }) => {
  // console.log('description', description);
  // const HTML_WITHOUT_FIGURE = description === undefined ? '' : description;
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  // const [convertedContent, setConvertedContent] = useState(null);

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);

    sendChildToParent(convertToHTML(editorState.getCurrentContent()));
    setEditorState(editorState);
    return editorState;
  };

  useEffect(() => {
    if (description) {
      htmlToDraftBlocks(description);
    }
  }, [description]);

  useEffect(() => {
    const HTML_WITHOUT_FIGURE = description === undefined ? '' : description;
    htmlToDraftBlocks(HTML_WITHOUT_FIGURE);
  }, []);

  // useEffect(() => {
  //   htmlToDraftBlocks(HTML_WITHOUT_FIGURE);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  const handleEditorChange = (state) => {
    console.log(state)
    setEditorState(state);
    convertContentToHTML(state);
  }

  const convertContentToHTML = (state) => {
    const styleToHTML = (style) => {
      if (style.startsWith('color-')) {
        return <span style={{ color: style.replace('color-', '') }} />;
      }
    };

    const blockToHTML = (block) => {
      if (block.type === 'unstyled') {
        return <p />;
      }
      // Add other block types if needed
    };

    const contentState = state.getCurrentContent();
    const currentContentAsHTML = convertToHTML({ styleToHTML, blockToHTML })(contentState);
    sendChildToParent(currentContentAsHTML);
  };

  // const createMarkup = (html) => {
  //   return {
  //     __html: DOMPurify.sanitize(html)
  //   }
  // }

  return (
    <div className="container">
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
      {
        // <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
      }
    </div>
  );
};

export default ControlledEditor;
