import axios from 'axios';
import createAuthRefreshInterceptor from "axios-auth-refresh";

axios.interceptors.request.use((req) => {
    const token = localStorage.getItem('token');
    if (token) {
        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
}, (error) => {
    return Promise.reject(error);
})

axios.interceptors.response.use((res) => { return res }, (error) => {
    // if ( error?.response?.status === 401 || error.response?.data?.message === 'Invalid or expired token') { 
    //     // console.log(error.config, 'config')
    //     getNewUserToken()
    //     document.dispatchEvent(new Event('unauthorizedError'));
    // }
    return Promise.reject(error);
})


const getNewUserToken = async () => {
    const refreshData = {
        refreshToken: localStorage.getItem('refreshToken'),
        pauseInstanceWhileRefreshing: true,
    }
    try {
        const response = await axios.post(`${API_URL}/refresh-token`, refreshData);
        if (response) {
            localStorage.setItem("token", response?.data?.accessToken);
            localStorage.setItem("refreshToken", response?.data?.refreshToken);
            // window.location.reload();
            return Promise.resolve();
        }
    } catch (error) {
        return Promise.reject(error.response.data);
    }
};

createAuthRefreshInterceptor(axios, getNewUserToken);

export const getRequest = async (url, type = null) => {
    try {
        let response;
        if (type != null) {
            response = await axios({
                url: url,
                method: 'GET',
                responseType: `${type}`,
            });
        }
        else {
            response = await axios.get(url);
        }
        return response.data;
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}

export const postRequest = async (url, data) => {
    try {
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        return Promise.reject(error?.response?.data);
    }
}

export const putRequest = async (url, data) => {
    try {
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}

export const patchRequest = async (url, data) => {
    try {
        const response = await axios.patch(url, data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export const deleteRequest = async (url) => {
    try {
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        return Promise.reject(error.response.data);
    }
}

export const API_URL = process.env.REACT_APP_API_BASE_URL;

