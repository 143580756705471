/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { UserServiceApi } from '../../api';
import { postRequest } from '../../api/http';
import { LoadingView } from '../common'
import Lady from "../../assets/images/lady.png";
import { useSearchParams } from "react-router-dom";
import HeaderWithOutAuth from "../header/headerWithOutAuth"
import './index.scss';

const VerifyEmail = (props) => {
    const [loading, setloading] = useState(false);
    const [isEmaiVerify, setIsEmailVerify] = useState(false)
    let [searchParams] = useSearchParams();
    const tokenParam = searchParams.get("token")

    const verifyEmail = async () => {
        setloading(true);
        try {
            const response = await postRequest(UserServiceApi.verifyEmail(tokenParam));
            console.log("response", response);
            if (response) {
                setloading(false);
                // toastr.success('Email verified Successfully');
                setIsEmailVerify(true)
            }

        } catch (e) {
            setloading(false);
            toastr.error(e.message);
            isEmaiVerify(false)
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (tokenParam) {
            verifyEmail()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenParam])

    function Imagelady() {
        return (
            <Image
                src={Lady}
                preview={false}
            />
        );
    }

    if (loading) return <LoadingView message="Loading..." />;
    return (

        <div className='verifyEmail'>
            <HeaderWithOutAuth />
            <div className="emailVerify">

                <div className='emailVerifyRow'>
                    <div className='emailVerifyCol imageLady'>
                        <Imagelady />
                    </div>
                    <div className='emailVerifyCol'>
                        {isEmaiVerify?
                        <h2>Successfully Verified your Email</h2>
                        :<p>Your Email address could not be verified.</p>}
                        <div className="backToLogin">
                            <span>Click here to <Link className="registerLink" to="/login"> Login</Link></span>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
}

export default VerifyEmail;
