import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, InputNumber } from 'antd';
import { postRequest } from '../../api/http';
import { JobsServiceApi } from '../../api';
import toastr from 'toastr';
import uuid from 'react-uuid';
import { LoadingView } from '../common';
const AddQuestionModalAi = ({ visible, onClose, onSubmit, initialValues ,questionData}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); 
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [previousValues, setPreviousValues] = useState({});
  const handleSubmit = async () => {
    const values=form.getFieldsValue()
    let requiredSkillsArray = Array.isArray(values?.required_skills)
    ? values?.required_skills
    : values?.required_skills?.split(',').map(skill => skill.trim());
     const data={
      job_title:values?.job_title,
      required_skills: requiredSkillsArray,
      roles:values?.roles,
      experience:values?.experience,
      previously_generated_questions:questionData?.map((q)=>{return q.question}),
      question_count:values?.question_count,
      user_prompt:values?.user_prompt
     }
     setLoading(true); 
    try {
      const response = await postRequest(JobsServiceApi.getQuestionsAi(),data);
        const generatedQuestions=transformMCQData(response.generated_mcq)
          toastr.success("Question Added Successfully")
          onSubmit(generatedQuestions)
          setPreviousValues(values);
          form.resetFields(["question_count"]);
          onClose()
          
  } catch (error) {
      toastr.error(error?.message);
  }finally {
    setLoading(false); 
  }
  };
  const transformMCQData = (mcqData) => {
    return mcqData?.map((item) => {
      const options = item.options; 
      return {
        question: `<p>${item.question}</p>`,
        optionA: options[0],
        optionB: options[1],
        optionC: options[2],
        optionD: options[3],
        correctAnswer: `OPTION_${String.fromCharCode(65 + item.correct_answer.index)}`, 
        id: uuid() // Generate a unique ID
      };
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      job_title: initialValues?.title || previousValues.job_title || null,
      experience: initialValues?.experienceLevel || previousValues.experience || null,
      required_skills: initialValues?.skills || previousValues.required_skills || null,
      roles: initialValues?.roles || previousValues.roles || null,
      question_count: initialValues?.question_count || previousValues.question_count || 1,
      user_prompt: initialValues?.user_prompt || previousValues.user_prompt || ""
    });
  }, [initialValues, previousValues, form]);
  const currentQuestionsCount = questionData?.length || 0;
  const maxQuestionsAllowed = 10;
  const remainingQuestions = maxQuestionsAllowed - currentQuestionsCount;
  const handleQuestionCountChange = (value) => {
    if (value + currentQuestionsCount > 10 || value > 6) {
      setDisableSubmit(true); // Disable submit button if the conditions are not met
    } else {
      setDisableSubmit(false); // Enable submit button if conditions are met
    }
  };
  return (
    <>
      <Modal
        title="Add AI Question"
        visible={visible}
        onCancel={onClose}
        onOk={handleSubmit}
        okText="Submit"
        confirmLoading={loading} 
        okButtonProps={{ disabled: loading || disableSubmit}} 
        cancelButtonProps={{ style: { display: 'none' } }} 
      >
        <Form
          form={form}
          layout="vertical"
          name="add_question_form"
          initialValues={{
            job_title: initialValues?.title ? initialValues?.title : "",
            experience: initialValues?.experienceLevel ? initialValues?.experienceLevel : "",
            required_skills: initialValues?.skills
              ? initialValues?.skills
              : null,
              question_count: 1,
          }}
          disabled={loading}
        >
          <Form.Item
            name="job_title"
            label="Job Title"
            rules={[{ required: true, message: 'Please input the job title!' }]}
          >
            <Input placeholder="e.g., Software Developer, Project Manager" />
          </Form.Item>

          <Form.Item
            name="required_skills"
            label="Required Skills"
            // rules={[{ required: true, message: 'Please input required skills!' }]}
          >
            <Input placeholder="e.g., JavaScript, React, Node.js" />
          </Form.Item>

          <Form.Item
            name="experience"
            label="Experience (Fresher or experienced)"
            // rules={[{ required: true, message: 'Please input the experience!' }]}
          >
            <Input placeholder="e.g., 2 years in software development" />
          </Form.Item>

          <Form.Item
            name="roles"
            label="Roles and responsibilities"
            // rules={[{ required: true, message: 'Please input the roles!' }]}
          >
            <Input placeholder="e.g., Develop and maintain web applications"/>
          </Form.Item>
          <Form.Item
              label={`Enter the number of questions to generate (1-6, up to ${remainingQuestions} remaining)`}
              name="question_count"
              rules={[
                {
                  required: true,
                  message: 'Please input the number of questions!',
                },
                () => ({
                  validator(_, value) {
                    if (!value || value <= 6) {
                      if (value + currentQuestionsCount <= 10) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(`You can add a maximum of ${remainingQuestions} more questions!`)
                      );
                    }
                    return Promise.reject(new Error('You can only generate up to 6 questions at once!'));
                  },
                }),
              ]}
            >
              <InputNumber
                min={1}
                max={Math.min(6, remainingQuestions)}
                onChange={handleQuestionCountChange} // Trigger change for validation
              />
            </Form.Item>
          <Form.Item
            name="user_prompt"
            label="Enter a Prompt for AI-Generated Questions"
            // rules={[{ required: true, message: 'Please input the user prompt!' }]}
          >
            <Input.TextArea placeholder="e.g., Generate questions on data structures and algorithms for software developers" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddQuestionModalAi;
