import React, { useRef, useState } from 'react';
import { Input, Tag, Modal, Button } from 'antd';
import toastr from 'toastr';
import StarRating from './jobFilterStarRate';
import './index.scss'

export default function JobFilter({ visible, onOk, jobCancel, setFilterValue }) {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [jobSeekerRating, setJobSeekerRating] = useState('')

  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleClose = (removedTag, key) => {
    const newTags = tags[key].filter((tag) => tag !== removedTag);
    setTags({ ...tags, [key]: newTags })
    setTags([])
    ratefunction("")
    setFilterValue([])
  };

  const checkValidate = (arr) => {
    const valueExist = arr.map(val => val)
    return new Set(valueExist).size !== valueExist.length
  }

  const handleInputConfirm = () => {
    const name = "skills"
    if (inputValue) {
      setTags(prev => {
        const arr = prev[name] ? [...prev[name], inputValue] : [inputValue]
        if (!checkValidate(arr)) {
          return { ...prev, [name]: [...arr] }
        } else {
          toastr.warning("Skills already exist");
          return { ...tags }
        }
      });
      setInputValue('');
    }
  };


  const handleSubmit = () => {
    onOk()
    const pathName = Object.keys(tags).map(key => {
      return `&${key}=${tags[key].map(tag => tag)}`
    })
    let ratingFilter = jobSeekerRating !== '' ? `&rating=${jobSeekerRating}`: ''
    const allFilters = [...pathName, ratingFilter]
    setFilterValue(allFilters.join(''))
  }

  const ratefunction = async (starCount) => {
    setJobSeekerRating(starCount)
  }

  const handleCancel = () => {
    jobCancel()
  }

  const handleClearFilter = () => {
    setTags([])
    ratefunction("")
    setFilterValue([])
  }
 
  return (
    <Modal 
    className='commonFilter' 
    title="Filter" 
    visible={visible} 
    onOk={handleSubmit} 
    onCancel={() => handleCancel()}
    footer={[
      <Button onClick={() => handleCancel()} className='filterSecondaryButton' key="Cancel">Cancel</Button>,
      <Button onClick={() => handleClearFilter()} className='filterSecondaryButton' key="Clear Filter">Clear Filter</Button>,
      <Button onClick={handleSubmit} key="OK" type="primary">
        OK
      </Button>
    ]}
    >
      <div className='filterTags'>
        {
          Object.keys(tags).map(key => {
            return tags[key].map((tag, i) => {
              return <Tag
                key={i}
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleClose(tag, key);
                }}
              >
                {tag}
              </Tag>
            })
          })
        }
      </div>
      <div className='filterInput'>
        <h4>Enter Skills</h4>
        <Input
          ref={inputRef}
          placeholder="Enter Filters"
          type="text"
          size="small"
          name='skills'
          value={inputValue}
          onChange={handleInputChange}
          onBlur={() => handleInputConfirm()}
          onPressEnter={() => handleInputConfirm()}
        />  
        <div className='jobseekerRating'>
        <h4>Enter Ratings</h4>
          <StarRating starData={(e) => ratefunction(e)} totalStars={5} starRate={jobSeekerRating} />
        </div>
      </div>
    </Modal>
  )
}
