import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Select, Modal } from 'antd';
import uuid from 'react-uuid';
import toastr from "toastr";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import { useParams } from "react-router";
import { JobsServiceApi } from "../../api";
import { postRequest } from "../../api/http";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const questionOptions = [
    { name: "Option A", value: "OPTION_A" },
    { name: "Option B", value: "OPTION_B" },
    { name: "Option C", value: "OPTION_C" },
    { name: "Option D", value: "OPTION_D" },
]

const AddQuestionModal = ({ addQuestionModal, cancelQuestionModal, setQuestionData,
    questionData, fetchQuestionByJob }) => {
    const { jobId } = useParams();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [loading, setloading] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const handleEditorStateChange = (val) => {
        setEditorState(val)
    }

    const emptyQuestionForm = () => {
        setEditorState(EditorState.createEmpty())
        form.setFields([
            { name: "correctAnswer", value: "", errors: "" },
            { name: "question", value: "", errors: "" },
            { name: "optionA", value: "", errors: "" },
            { name: "optionB", value: "", errors: "" },
            { name: "optionC", value: "", errors: "" },
            { name: "optionD", value: "", errors: "" }
        ])
    }

    const handleQuestion = async (values) => {
        if (jobId) {
            const addData = {
                ...values,
                question: convertToHTML(editorState.getCurrentContent()),
            }
            try {
                setloading(true)
                const response = await postRequest(JobsServiceApi.createJobQuestion(jobId), addData);
                if (response) {
                    toastr.success("Question Added Successfully");
                    fetchQuestionByJob(jobId)
                    setloading(false)
                }
            } catch (error) {
                toastr.error(error?.message);
                setloading(false)
            }

        } else {
            if (questionData?.length < 10) {
                setQuestionData([...questionData, { ...values, question: convertToHTML(editorState.getCurrentContent()), id: uuid() }])
            } else {
                toastr.error("Cannot create more questions, because you have created maximum questions for a job");
            }
        }
        emptyQuestionForm()
        cancelQuestionModal()
    }

    const handleCloseQuestionModal = () => {
        emptyQuestionForm()
        cancelQuestionModal()
    }

    return (
        <Modal
            title=""
            visible={addQuestionModal}
            onOk={handleCloseQuestionModal}
            onCancel={handleCloseQuestionModal}
            width={800}
            footer={null}>
            <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={handleQuestion}
                initialValues={{
                    remember: true,
                }}
            >
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Question"
                            name="question"
                            rules={[{ required: true, message: 'Please enter Question' },
                            () => ({
                                validator(_, value) {
                                    if (!editorState.getCurrentContent().hasText()) {
                                        return Promise.reject(`Please enter Question`);
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        validateFirst={true}
                        >
                            <Editor
                                editorState={editorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                onEditorStateChange={handleEditorStateChange}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Option A"
                            name="optionA"
                            rules={[{ required: true, message: 'Please enter option A' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Option B"
                            name="optionB"
                            rules={[{ required: true, message: 'Please enter option B' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Option C"
                            name="optionC"
                            rules={[{ required: true, message: 'Please enter option C' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Option D"
                            name="optionD"
                            rules={[{ required: true, message: 'Please enter option D' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <Form.Item
                            label="Select answer"
                            name="correctAnswer"
                            rules={[{ required: true, message: 'Please select Answer' }]}
                        >
                            <Select
                                placeholder="Please select Answer"
                                allowClear
                            >
                                {questionOptions?.map(opt => (
                                    <Option value={opt.value}>{opt.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }} className="create-question-btn">
                        <Button type="primary submit" htmlType="submit" disabled={loading} loading={loading}>
                            Add Question
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddQuestionModal;
