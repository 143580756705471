import React from 'react';
import { Image } from 'antd';
import ErrorIcon from '../../../assets/images/icons/Pagenotfound.svg';

export default function PageNotFound(props) {
    return (
        <div className="container">
            <div className='errorMsg'>
                <Image src={ErrorIcon} preview={false} />
                <h1>404</h1>
                <p>There's nothing here!</p>
            </div>
        </div>
    );
}
