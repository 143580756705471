import React, { useEffect, useState } from "react";
import "./index.scss";
import { getRequest, postRequest } from "../../api/http";
import employersServiceApi from "../../api/employersServiceApi";
import { LoadingView } from "../common";
import { Button, Modal } from "antd";
import toastr from "toastr";
import OngoingIcon from "../../assets/images/icons/ongoing.png";
import UpcomingIcon from "../../assets/images/icons/upcoming.png";
import bannerLogo from "../../assets/images/college-banner.svg";
import CollegeLogo from "../../assets/images/college-logo.jpg";
import moment from "moment";
import EventDetailsModal from "./EventDetailsModal";
import axios from "axios";
import { InfoCircleOutlined } from "@ant-design/icons";

function EmployerEvent() {
  const token = localStorage.getItem("token");
  const { confirm } = Modal;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [paymentFormStatus, setPaymentFormStatus] = useState(false);
  const [paymentForm, setPaymentForm] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);

  const splitDateTime = (dateTimeString) => {
    const localTime = moment.utc(dateTimeString).local();
    return localTime.format("H:mm, MMM D, YYYY");
  };
  const updateSubscriptionPlans = async (planId,eventId) => {
    console.log("first", planId);
    const requestOptions = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      hostname: window.location.hostname,
      eventId:eventId,// Include the hostname in the request options
    };
    setLoading(true);
    console.log("Value:::", planId);
    axios
      .post(
        process.env.REACT_APP_API_BASE_URL +
        `/employers/current/subscriptions/${planId}`,
        requestOptions
      )
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          setPaymentFormStatus(true);
          setPaymentForm(response.data);
          setLoading(false);
        } else {
          setPaymentForm(null);
          setPaymentFormStatus(false);
          setLoading(false);
        }
      })
      .catch((e) => {
        toastr.error(e.message);
        setPaymentFormStatus(false);
        setLoading(false);
      });
  };

  function Iframe(props) {
    return <div dangerouslySetInnerHTML={{ __html: paymentForm }} />;
  }

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const response = await getRequest(employersServiceApi.getAllEvents());
      setEvents(response);
    } catch (e) {
      // toastr.error(e?.response?.message || e?.message);
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleRegistration = async (eventId) => {
    const data = {
      eventId: eventId,
    };

    try {
      console.log("data", data);
      await postRequest(employersServiceApi.registerEvent(), data);
      toastr.success("You have been successfully registered for the event");

      // Handle success response
      // console.log("Registration success:", response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    } finally {
      fetchEvents();
    }
  };
  const backAction = () => {
    setPaymentFormStatus(false);
    setPaymentForm(null);
  };
  const showEventDetailsModal = (event) => {
    fetchEventDetails(event);
  };
  const fetchEventDetails = async (eventId) => {
    try {
      // setLoading(true);
      const response = await getRequest(employersServiceApi.getEvent(eventId)); // Assuming getEvent fetches event details by ID
      setEventDetails(response);
    } catch (error) {
      console.error("Error fetching event details:", error);
    } finally {
      setLoading(false);
    }
  };
  // Close event details modal
  const closeEventDetailsModal = () => {
    setEventDetails(null);
  };
  const showConfirm = (eventId) => {
    confirm({
      title: "Confirmation",
      icon: "",
      content: "would you like to register for this event?",
      okText: "Yes",
      cancelText: "No",
      okButtonProps: {
        // style: {
        //   borderRadius: "4px", // Adjust the border radius as needed
        //   width: "80px", // Set the width of the button
        // },
      },
      cancelButtonProps: {
        style: {
          borderRadius: "7px",
          minWidth: "100px",
          height: "auto",
          fontSize: "16px",
          fontWeight: "400",
        },
      },
      onOk() {
        handleRegistration(eventId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  if (loading) return <LoadingView />;
  return (
    <div className="registerEvent">
      {paymentFormStatus ? (
        <React.Fragment>
          <div className="headingContainer">
            <div className="headingTitle">
              <h3>Billing</h3>
            </div>
            <div className="headingButton">
              <Button
                className="btn ant-btn ant-btn-primary backBtn"
                onClick={() => backAction()}
              >
                Back
              </Button>
            </div>
          </div>
          <Iframe />
        </React.Fragment>
      ) : (
        <>
          <div className="headingContainer">
            <div className="headingTitle" style={{ whiteSpace: "nowrap" }}>
              <h3>"Explore Exciting Events and Hire Top Talent!"</h3>
            </div>
          </div>
          <div className="event-list">
            {events.map((event) => (

              <div key={event.id} className="event-card">
                <div>
                  <img
                    src={event.bannerImageUrl || bannerLogo}
                    alt="Banner"
                    className="banner"
                  />
                  {event?.eventStatus === "ONGOING" ? (
                    <img src={OngoingIcon} alt="" className="tag" />
                  ) : event?.eventStatus === "UPCOMING" ? (
                    <img src={UpcomingIcon} alt="" className="tag" />
                  ) : null}

                  <div className="logo-container">
                    <img
                      src={event.logoUrl || CollegeLogo}
                      alt="Company Logo"
                      className="logo"
                    />
                  </div>
                </div>
                <div className="card-content">
                  <div className="details-of-event">
                    <div className="name">{event.title}</div>
                    <div className="date">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="name" style={{ marginBottom: "0px" }}>
                            {truncateText(event?.name, 40)}
                          </div>
                          <div style={{ margin: "6px" }}>
                            <InfoCircleOutlined
                              onClick={() => showEventDetailsModal(event.id)}
                              className="about-company-icon"
                            />
                          </div>
                        </div>

                        <div>
                          {event.membershipStatus === "REQUEST" ? (
                            <div className="register-button">
                              <span className="registered">Requested</span>
                            </div>
                          ) : event.membershipStatus === "APPROVE" ? (
                            <div className="register-button">
                              <span className="Approved">Approved</span>
                            </div>
                          ) : event.membershipStatus === "REJECTED" ? (
                            <div className="register-button">
                              <span
                                className="reject"
                                onClick={() => showConfirm(event.id)}
                              >
                                Register Again
                              </span>
                            </div>
                          ) : (
                            <div className="register-button">
                              <span
                                className="view-all-link"
                                onClick={() => showConfirm(event.id)}
                              >
                                Register
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                      <div className="start-date">Starts from </div>
                      <div style={{ marginLeft: '5px' }}> {splitDateTime(event.startDate)}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div className="end-date">Ends on </div>
                      <div style={{ marginLeft: '5px' }}> {splitDateTime(event.endDate)}</div>
                    </div>
                      <div className="type">{truncateText(event?.venue, 25)}</div>
                    </div>

                    
                  </div>
                  <div className="pay-card">
                    {event?.membershipStatus === "APPROVE" &&
                      event?.subscriptionPlan?.planId ? (
                      <>

                        {event.subscribed === true ? (
                          <>{
                            event?.subscriptionPlan?.price > 0 ?(
                            <div>
                              <div style={{ color: "#2d83bf" }}>
                                Payment Verified
                              </div>
                            </div>
                          ):null} 
                          </>
                        ) : (
                          <>
                            <div>
                              <Button
                                type="primary"
                                className="buy-now-button"
                                onClick={() =>
                                  updateSubscriptionPlans(
                                    event.subscriptionPlan.planId,
                                    event.id
                                  )
                                }
                              >
                                Subscribe now
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    ) : event?.membershipStatus === "APPROVE" ? (
                      <div>No subscription plan available</div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <EventDetailsModal
        visible={!!eventDetails}
        event={eventDetails}
        onClose={closeEventDetailsModal}
      />
    </div>
  );
}

export default EmployerEvent;
