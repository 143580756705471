import { Button, Col, Row } from "antd";
import React from "react";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Questions = ({ questionData, setQuestionEditData, deleteQuestionModal, setDeleteQuestionModal,
     setQuestionDeleteData, viewQuestionModal, setViewQuestionModal }) => {

     const handleEdit = (val) => {
          
          setQuestionEditData(val)
          setViewQuestionModal(!viewQuestionModal)
     }

     const handleDelete = (val) => {
          setQuestionDeleteData(val)
          setDeleteQuestionModal(!deleteQuestionModal)
     }

     return (
          <div >
               {questionData?.map((item, i) => {
                    return <div className="question-grid" key={i}>
                         <Row gutter={16}>
                              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 16}} className="gutter-row question-cursor-pointer" onClick={() => handleEdit(item)}>
                                   <div className="text-over-flow"
                                        dangerouslySetInnerHTML={{ __html: item?.question }}
                                   />
                              </Col>
                              <Col xs={{ span: 14 }} sm={{ span: 14 }} lg={{ span: 5}} className="gutter-row text-over-flow question-cursor-pointer" onClick={() => handleEdit(item)}>
                                   <span className="question-correct-answer">{item?.correctAnswer?.replace("_", " ")}</span> is the correct answer.
                              </Col>
                              <Col xs={{ span: 10 }} sm={{ span: 10 }} lg={{ span: 3}} className="gutter-row question-action-btn">
                                   <Button className="question-edit-btn" type='primary' onClick={() => handleEdit(item)}><EditOutlined /></Button>
                                   <Button onClick={() => handleDelete(item)} className='question-delete-btn' type='primary'><DeleteOutlined /></Button>
                              </Col>
                         </Row>
                    </div>
               })}
          </div>
     )
}

export default Questions